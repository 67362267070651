import React from 'react';

import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectContent } from '../../../../store/Selectors';
import { ReactComponent as IconArrowSvg } from '../../../../svgs/iconArrow.svg';
import ActionButton from '../../actionbutton/ActionButton';
import ContainedRow from '../../layout/ContainedRow';

interface Props {
  resetBookingRef: () => void;
  bookingRef: string;
  pathname: string;
}

const ContinueButtons: React.FC<Props> = ({
  pathname,
  bookingRef,
  resetBookingRef,
}) => {
  const navigate = useNavigate();
  const content = useSelector(selectContent);
  return (
    <ContainedRow classNameCol='text-center'>
      <ActionButton
        onClick={() => navigate(pathname)}
        disabled={!bookingRef}
        stickyMobileDesktop={false}
        showIcon
        contained
        mx={5}
        variant='primary'
      >
        {content.concessionsOnlyJourney.landingStartConcessionsButtonText}
      </ActionButton>

      <Button
        onClick={resetBookingRef}
        className={
          'd-flex align-items-center justify-content-between back px-4 mt-1 w-100'
        }
        variant='link'
      >
        {content.concessionsOnlyJourney.landingGoBackButtonText}
        <IconArrowSvg className='icon-arrow d-flex align-items-center' />
      </Button>
    </ContainedRow>
  );
};

export default ContinueButtons;
