import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { Concession, GlobalState } from '../../../../@types/modelTypes';
import { getMatchingConcessionDeal } from '../../../../services/Helpers';
import {
  selectContent,
  selectDeals,
  selectDisplayPrice,
} from '../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../svgs/deal.svg';
import GiftCardImage from '../GiftCardImage';

interface GiftCardsRowProps {
  selectedGiftCard: Concession;
}

const PaymentSummaryRowEGiftCard: React.FC<GiftCardsRowProps> = ({
  selectedGiftCard,
}) => {
  const content = useSelector(selectContent);
  const deals = useSelector(selectDeals);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, selectedGiftCard?.cost)
  );
  if (!selectedGiftCard || selectedGiftCard.quantity < 1) return null;

  const getDescription = () => {
    return `${content.giftCards.summaryGiftCardLabel} x ${selectedGiftCard.quantity}`;
  };

  const matchingDeal = getMatchingConcessionDeal(selectedGiftCard.id, deals);

  return (
    <Box className='giftcards-row' mx={4}>
      <Flex
        sx={{
          py: 6,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Box sx={{ pr: 4, position: 'relative', justifySelf: 'flex-start' }}>
            <GiftCardImage
              imageUrl={
                selectedGiftCard.image || content.giftCards.defaultImage
              }
            />
            {!!matchingDeal && <DealSvg className='deal-icon' />}
          </Box>
          <Box
            className='summary-item'
            sx={{
              fontWeight: 'bold',
              textAlign: 'left',
              justifySelf: 'flex-start',
            }}
          >
            {getDescription()}
            {matchingDeal && (
              <div className='mb-1 text-uppercase deal-text'>
                {matchingDeal.description}
              </div>
            )}
          </Box>
        </Flex>
        <Box
          className='summary-item'
          sx={{
            fontWeight: 'bold',
            textAlign: 'right',
            justifySelf: 'flex-end',
          }}
        >
          {priceToDisplay}
        </Box>
      </Flex>
    </Box>
  );
};

export default PaymentSummaryRowEGiftCard;
