import React, { useState } from 'react';

import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  concessionItemSxProp,
  descriptionSxProp,
  detailSxProp,
  priceSxProp,
  quantitySxProp,
} from './constants';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import {
  displayPrice,
  getMatchingConcessionDealDetails,
  handleAddRemoveConcessionDynamicBasket,
  handleConcessionQuantityChange,
} from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectCurrencyConfig,
  selectDeals,
  selectSelectedDeliveryWindow,
  selectToken,
  selectTotalGiftCardsSelected,
  selectJourneyTypeConfig,
  selectContent,
  selectSelectedGiftCards,
} from '../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../svgs/deal.svg';
import { ReactComponent as SummaryCloseSvg } from '../../../svgs/summaryClose.svg';
import { ReactComponent as SummaryEditSvg } from '../../../svgs/summaryEdit.svg';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  hideTax: boolean;
  pathname: string;
}

const CartSummaryGCConcessions: React.FC<Props> = ({ hideTax, pathname }) => {
  const dispatch = useDispatch();
  const currencyConfig = useSelector(selectCurrencyConfig);
  const config = useSelector(selectConfig);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const deals = useSelector(selectDeals);
  const selectedGiftcardsCount = useSelector(selectTotalGiftCardsSelected);
  const dataToken = useSelector(selectToken);
  const bookingData = useSelector(selectBookingData);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const content = useSelector(selectContent);
  const selectedGCConcessions = useSelector(selectSelectedGiftCards);

  const [concessionToEdit, setConcessionToEdit] = useState<
    Concession | undefined
  >(undefined);

  const maxQuantity = journeyTypeConfig.usesPhysicalGiftCardsConfigs
    ? config.giftCardsPurchase.maxQuantityOfPhysicalGiftCards
    : config.giftCardsPurchase.maxQuantityOfGiftCards;

  const canEdit =
    (!journeyTypeConfig.isConcessionsOnlyJourney ||
      (journeyTypeConfig.isConcessionsOnlyJourney && maxQuantity > 1)) &&
    (pathname.startsWith('/kiosk/') ||
      pathname.startsWith('/giftcards/select') ||
      pathname.startsWith('/giftmembership/select') ||
      pathname.startsWith('/physicalgiftcards/select'));

  const toggleEdit = (c: Concession) => {
    setConcessionToEdit(concessionToEdit === c ? undefined : c);
  };

  const handleConcessionChange = async (
    context: QuantitySelectorContext,
    concession: Concession | undefined,
    concessionIndex: number
  ) => {
    if (config.useDynamicBasket) {
      const concessionToChange: Concession = JSON.parse(
        JSON.stringify(concession)
      );
      concessionToChange.quantity = 1;
      dispatch(actionCreators.setLoading(true));
      await handleAddRemoveConcessionDynamicBasket(
        context,
        concessionToChange,
        dataToken,
        journeyTypeConfig.type,
        selectedDeliveryWindow,
        bookingData,
        dispatch,
        content
      );
      dispatch(actionCreators.setLoading(false));
    }

    if (context == 'add') {
      handleConcessionQuantityChange(
        'add',
        concession,
        concessionIndex,
        dispatch,
        false
      );
    } else {
      handleConcessionQuantityChange(
        'remove',
        concession,
        concessionIndex,
        dispatch,
        false
      );
    }
  };

  const getEditRow = (c: Concession, ci: number) => {
    return (
      <Flex
        className='concessions-item-edit'
        sx={{ justifyContent: 'flex-end' }}
      >
        <Flex
          className='quantity-selector'
          sx={{ alignItems: 'center', mt: 2 }}
        >
          <QuantityButton
            context='remove'
            disabled={c.quantity <= 0}
            onClick={() => handleConcessionChange('remove', c, ci)}
            size='tiny'
          />
          <div className='flex-grow-1 text-center'>{c.quantity}</div>
          <QuantityButton
            context='add'
            disabled={isAddDisabled()}
            onClick={() => handleConcessionChange('add', c, ci)}
            size='tiny'
          />
        </Flex>
      </Flex>
    );
  };

  const isAddDisabled = () => {
    if (selectedGCConcessions == null) {
      return false;
    }
    return selectedGiftcardsCount >= maxQuantity;
  };

  const getDescription = (c: Concession) => {
    if (journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY) {
      return `${displayPrice(c.cost, currencyConfig)} ${
        content.giftCards.summaryGiftCardLabel
      }`;
    } else {
      return c.description;
    }
  };

  const concessionWithModifiers = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    return (
      <>
        <Flex className='concession-item' sx={concessionItemSxProp}>
          <Flex sx={descriptionSxProp}>{getDescription(c)}</Flex>
          <Flex sx={detailSxProp}>
            <Box className={classnames('tickets-quantity')} sx={quantitySxProp}>
              {c.quantity}
            </Box>
            <Box className='price' sx={priceSxProp}>
              {displayPrice(
                c.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          </Flex>
        </Flex>
        {c.modifiers?.map((m: Modifier, mi: number) => (
          <Box
            key={`c_${c.id}_${ci}_m_${m.id}_${mi}`}
            sx={{ textAlign: 'left' }}
          >
            {'- '}
            {m.description}
            {m.quantity > 1 && <span> x {m.quantity}</span>}
          </Box>
        ))}
      </>
    );
  };

  const concessionWithModifierGroups = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    const isEditing = canEdit && concessionToEdit === c;
    const matchedConcessionDeal = getMatchingConcessionDealDetails(c.id, deals);
    return (
      <>
        <Flex className='concession-item' sx={concessionItemSxProp}>
          <Flex sx={descriptionSxProp}>
            {matchedConcessionDeal && <DealSvg className='deal-icon' />}
            {getDescription(c)}
          </Flex>
          <Flex sx={detailSxProp}>
            <Flex
              className={classnames(
                'edit-col',
                (canEdit && !c.isDeliveryItem) || 'invisible'
              )}
              sx={{ justifyContent: 'center', alignItems: 'center' }}
              onClick={() => toggleEdit(c)}
            >
              <Button
                className='close-button text-uppercase tiny'
                variant='link'
              >
                {isEditing
                  ? content.cartSummary.closeButtonText
                  : content.cartSummary.editButtonText}
              </Button>
              {isEditing ? (
                <SummaryCloseSvg className='icon d-flex align-items-center' />
              ) : (
                <SummaryEditSvg className='icon d-flex align-items-center' />
              )}
            </Flex>
            <Box
              className={classnames(
                'tickets-quantity',
                isEditing && 'invisible'
              )}
              sx={quantitySxProp}
            >
              {c.quantity}
            </Box>
            <Box className='price' sx={priceSxProp}>
              {displayPrice(
                c.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          </Flex>
        </Flex>
        {c.modifierGroups?.map((mg: ModifierGroup, mgi: number) => (
          <div key={`c_${c.id}_${ci}_mg_${mg.id}_${mgi}`}>
            {mg.modifiers
              .filter((x: Modifier) => x.quantity > 0)
              .map((m: Modifier, mi: number) => (
                <Box
                  sx={{ textAlign: 'left' }}
                  key={`c_${c.id}_${ci}_mg_${mg.id}_${mgi}_m_${m.id}_${mi}`}
                >
                  {'- '}
                  {m.description}
                  {mg.maximumQuantity > 1 && m.quantity > 0 && (
                    <span> x {m.quantity}</span>
                  )}
                </Box>
              ))}
          </div>
        ))}
        {isEditing && getEditRow(c, ci)}
      </>
    );
  };

  if (!selectedGiftcardsCount) return null;
  return (
    <div className='cart-summary-row'>
      {selectedGCConcessions?.list.map(
        (c: Concession, ci: number) =>
          c.quantity > 0 &&
          !c.isDeliveryItem && (
            <div
              className='concessions-item-list'
              data-testid='cs-concessions'
              key={`c_${c.id}_${ci}`}
            >
              {c.modifierGroups
                ? concessionWithModifierGroups(c, hideTax, ci)
                : concessionWithModifiers(c, hideTax, ci)}
            </div>
          )
      )}
    </div>
  );
};

export default CartSummaryGCConcessions;
