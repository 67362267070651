import React, { useEffect, useCallback, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import { SeasonPass } from '../../../@types/modelTypes';
import {
  forceNewJourneyKey,
  JOURNEY_TYPES,
  PEACH_CODES,
} from '../../../constants';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectStep } from '../../../store/Selectors';

const StartSeasonPass: React.FC = () => {
  const [cookies] = useCookies();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const cinemaId = searchParams.get('cinemaId');
  const step = useSelector(selectStep);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const journeyType = JOURNEY_TYPES.SEASON_PASS;

  const getJourneyDataUsingCinemaId = useCallback(async () => {
    dispatch(actionCreators.clearSession());
    dispatch(actionCreators.setLoading(true));
    const { requestData, preferredLanguageCulture } = cookies;
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const circuitId = searchParams.get('circuitId') ?? undefined;
    const selectedLanguageCulture =
      preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    if (requestData) {
      dispatch(actionCreators.setRequestData(requestData));
    }

    const data = {
      selectedLanguageCulture,
      circuitId,
      cinemaId,
      requestData,
    };
    const path = `api/StartSeasonPass`;
    const response = await backend.post(path, data);
    let bookingData, config, content, customer, result, reservedSeats;
    if (
      response.ok &&
      (response.content.peachCode == PEACH_CODES.ok ||
        response.content.peachCode == PEACH_CODES.noError)
    ) {
      result = response.content;
      bookingData = result.bookingData;
      reservedSeats = result.reservedSeats;
      config = result?.circuit.config;
      content = result?.circuit.content;

      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );

      delete config.cinemas;
      delete content.cinemas;

      const filterPromotionId = searchParams.get('promotionId');

      const filteredSeasonPasses: SeasonPass[] =
        filterPromotionId &&
        result.seasonPasses.filter(
          (seasonPass: SeasonPass) => seasonPass.id === filterPromotionId
        );

      const seasonPasses =
        filteredSeasonPasses?.length > 0
          ? filteredSeasonPasses
          : result.seasonPasses;

      const processSeasonPasses: SeasonPass[] = seasonPasses.map(
        (seasonPass: SeasonPass) => ({
          ...seasonPass,
          ticketTypes: seasonPass.ticketTypes.map((ticketType) => ({
            ...ticketType,
            quantity: ticketType.quantity ?? 0,
          })),
        })
      );

      const isUserValidated = bookingData?.isUserValidated;
      const allowSignIn =
        config.currentCinema?.allowSignIn || config.signIn.allowSignIn;

      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        currency: 'USD',
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: { ticketTypeModels: [] },
        donation: 0,
        bookingFee: 0,
        bookingFeeStrategy: result?.bookingFeeStrategy,
        seatsModel: result.selectSeatsModel,
        error: { show: false, message: '' },
        selectedSeats: reservedSeats,
        giftCard: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: isUserValidated,
        journeyType: journeyType,
        customer: customer,
        source: source,
        device: device,
        step: allowSignIn && !isUserValidated ? 0 : 1,
        kioskSubStep: 'fab',
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        selectedDeliveryWindow: null,
        selectedLanguageCulture: content.culture,
        gratuityLimitInCents: result.gratuityLimitInCents,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
        seasonPasses: processSeasonPasses,
      };

      if (config.sentryDsnKey) {
        loadSentry(config.sentryDsnKey);
      }

      dispatch(actionCreators.initializeSession(session));

      window.sessionStorage.removeItem(forceNewJourneyKey);

      setShouldRedirect(true);
    } else {
      result = response.errorContent;
      config = result?.circuit.config;
      content = result?.circuit.content;

      delete config.cinemas;
      delete content.cinemas;

      const session = {
        loading: true,
        config: config,
        content: content,
        error: {
          show: true,
          message: getContentForError(result.peachCode, content),
        },
      };

      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      dispatch(actionCreators.initializeSession(session));
      dispatch(actionCreators.setToken(result.dataToken));

      window.sessionStorage.removeItem(forceNewJourneyKey);

      setShouldRedirect(true);
    }

    dispatch(actionCreators.setLoading(false));
  }, [dispatch, searchParams, cookies, location, cinemaId, journeyType]);

  useEffect(() => {
    if (cinemaId) {
      getJourneyDataUsingCinemaId();
    }
  }, [cinemaId, getJourneyDataUsingCinemaId]);

  const stepNumber = step;
  const journeyTypeConfig = journeyTypeConfigs[journeyType];
  const pathname = `/${getRouteFromStep(
    journeyTypeConfig,
    stepNumber
  )}/${cinemaId}`;

  return shouldRedirect ? (
    <Navigate to={pathname} state={{ from: location }} />
  ) : null;
};

export default StartSeasonPass;
