/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useRef } from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import FandBItem from './FandBItem';
import FandBMiniCategories from './FandBMiniCategories';

import { Concession, ConcessionGrouping } from '../../../@types/modelTypes';
import {
  selectCurrentCinema,
  selectFaBConcessions,
  selectKioskActiveCategory,
} from '../../../store/Selectors';
import Heading from '../heading/Heading';
import ContainedRow from '../layout/ContainedRow';

const FandBItemList: React.FC = () => {
  const activeCategory = useSelector(selectKioskActiveCategory);
  const currentCinema = useSelector(selectCurrentCinema);
  const concessions = useSelector(selectFaBConcessions);

  const tabContainer = useRef<null | HTMLDivElement>(null);

  return (
    <>
      <FandBMiniCategories />
      <ContainedRow>
        <Box
          ref={tabContainer}
          sx={{ marginTop: 6, scrollMarginTop: '90px' }}
          variant='contained'
        >
          {concessions?.map((cg: ConcessionGrouping, i: number) => {
            const showImageColumn =
              currentCinema?.foodAndBeverageHideAllItemImages
                ? false
                : cg.items.some((c: Concession) => c.image);
            return (
              <Box
                variant='boxes.containedLarge'
                key={`category-tab-${cg.title}`}
                sx={{ display: activeCategory === i ? 'block' : 'none' }}
              >
                <Box
                  mb={1}
                  pb={3}
                  sx={{
                    borderBottom: 'bodySeparator',
                  }}
                >
                  <Heading size={2}>{cg.title}</Heading>
                </Box>
                {cg.items?.map((concession: Concession) => (
                  <FandBItem
                    concession={concession}
                    key={concession.id}
                    showImageColumn={showImageColumn}
                  />
                ))}
              </Box>
            );
          })}
        </Box>
      </ContainedRow>
    </>
  );
};

export default FandBItemList;
