import React, { FC, memo, useEffect, useState } from 'react';

import moment from 'moment';
import { Button, Form } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Box, Flex } from 'theme-ui';
import { isLength } from 'validator';

import messages from './intl';

import {
  Concession,
  GlobalState,
  LoginDetailsState,
  LoginRequestModel,
  LoginResponseModel,
} from '../../../@types/modelTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { handleExternalLogInClick } from '../../../services/LoyaltyHelpers';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectToken,
  selectSelectedFaBConcessions,
  selectSource,
  selectState,
  selectStep,
  selectForgottenPasswordUrl,
  selectJourneyTypeConfig,
  selectConfig,
  selectContent,
  selectDazzlerLocale,
} from '../../../store/Selectors';
import { ReactComponent as EyeHideSvg } from '../../../svgs/eyeHide.svg';
import { ReactComponent as EyeShowSvg } from '../../../svgs/eyeShow.svg';
import ActionButton from '../../common/actionbutton/ActionButton';
import CheckBoxButton from '../../common/checkboxbutton/CheckBoxButton';
import ContainedRow from '../../common/layout/ContainedRow';
import CaptchaText from '../../dazzlercommon/captchatext/CaptchaText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import { WidgetData } from '../types';

type Props = {
  setIsDropdownOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  widget: WidgetData<'TicketingCMSJourneyLoginWidget'>;
};

export const Login: FC<Props> = ({ setIsDropdownOpen, widget }) => {
  const { formatMessage } = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const analytics = useAnalytics();
  const [cookies, setCookies, removeCookies] = useCookies();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const locale = useSelector(selectDazzlerLocale);
  const bookingData = useSelector(selectBookingData);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const source = useSelector(selectSource);
  const state = useSelector(selectState);
  const step = useSelector(selectStep);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const forgottenPasswordUrl = useSelector(selectForgottenPasswordUrl);

  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(false);
  const [isRememberUsernameChecked, setIsRememberUsernameChecked] = useState(
    !!cookies.rememberUsername || false
  );
  const [loginDetailsState, setLoginDetailsState] = useState<LoginDetailsState>(
    {
      username: cookies.rememberUsername || '',
      usernameIsValid: cookies.rememberUsername || false,
      usernameIsValidated: cookies.rememberUsername || false,
      password: '',
      passwordIsValid: false,
      passwordIsValidated: false,
      isValid: false,
    }
  );
  const [validateForm, setValidateForm] = useState(false);

  const hasConcessions = selectedConcessions?.list
    ? selectedConcessions.list.some((x: Concession) => x.quantity > 0)
    : false;

  useEffect(() => {
    if (validateForm) {
      setLoginDetailsState((prevState) => ({
        ...prevState,
        usernameIsValidated: true,
        passwordIsValidated: true,
      }));
    }
  }, [validateForm]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const autofillElements = document.querySelectorAll(
        'input:-webkit-autofill'
      );
      const isValid =
        (loginDetailsState.usernameIsValid && autofillElements.length === 1) ||
        autofillElements.length === 2;

      handleLoginDetailsStateChange({
        ...loginDetailsState,
        isValid: isValid,
      });
    }, 300);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginDetailsStateChange = (
    nextLoginDetailsState: LoginDetailsState
  ) => {
    setLoginDetailsState(nextLoginDetailsState);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const username = e.currentTarget.value;
    const usernameIsValid = isLength(username, { min: 1, max: 50 });
    const isValid = usernameIsValid && loginDetailsState.passwordIsValid;

    handleLoginDetailsStateChange({
      ...loginDetailsState,
      username,
      usernameIsValid,
      isValid,
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.currentTarget.value;
    const passwordIsValid = isLength(password, { min: 1, max: 50 });
    const isValid = passwordIsValid && loginDetailsState.usernameIsValid;

    handleLoginDetailsStateChange({
      ...loginDetailsState,
      password,
      passwordIsValid,
      isValid,
    });
  };

  const handleRememberUsernameCookie = () => {
    if (isRememberUsernameChecked) {
      const cookieOptions = {
        path: '/',
        expires: moment().add(5, 'y').toDate(),
        secure: true,
        sameSite: true,
      };
      setCookies('rememberUsername', loginDetailsState.username, cookieOptions);
    } else {
      removeCookies('rememberUsername', { path: '/' });
    }
  };

  const onForgottenPasswordClick = () => {
    handleExternalLogInClick(
      journeyTypeConfig,
      bookingData,
      source,
      forgottenPasswordUrl
    );
  };

  const navigateToNextStep = () => {
    const path = getRouteFromStep(journeyTypeConfig, 1);
    const fullPath = !journeyTypeConfig.isConcessionsOnlyJourney
      ? `/${path}/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`
      : `/${path}`;
    navigate(fullPath);
  };

  const navigateToStartTicketing = (requestData: string) => {
    window.location.href = `/api/startticketing/redirect/${
      bookingData.externalCinemaId
    }/${bookingData.externalSessionId}?circuitId=${
      config.circuitId
    }&requestData=${encodeURI(requestData)}`;
  };

  const navigateToPreviousStep = () => {
    const path = getRouteFromStep(journeyTypeConfig, step - 1);
    const fullPath = !journeyTypeConfig.isConcessionsOnlyJourney
      ? `/${path}/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`
      : `/${path}`;
    navigate(fullPath);
  };

  const navigateToStepOne = () => {
    navigate(
      `/${journeyTypeConfig.routes[1]}/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`
    );
  };

  const shouldRedirectToPreviousStep =
    !config.signIn.redirectToStepOneAfterLoggingIn &&
    pathname.includes('/payment') &&
    journeyTypeConfig.hasKioskStep &&
    hasConcessions;

  const shouldRedirectToStepOne =
    !journeyTypeConfig.isConcessionsOnlyJourney &&
    config.signIn.redirectToStepOneAfterLoggingIn;

  //should be a widget setting?
  const shouldRedirectToStartTicketing =
    config.isFoodAndBeverageLoyaltyOnly &&
    !journeyTypeConfig.isConcessionsOnlyJourney;

  const loginMember = async () => {
    if (!bookingData || !config || !executeRecaptcha) return null;

    dispatch(actionCreators.setLoading(true));
    setHasError(false);
    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'Login',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const data: LoginRequestModel = {
      username: loginDetailsState.username,
      password: loginDetailsState.password,
      dataToken: dataToken,
      journeyType: journeyTypeConfig.type,
      recaptchaToken: recaptchaToken ?? null,
    };

    const response = await backend.post(
      'api/Member/login',
      data,
      turnstileToken
    );

    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      const responseContent: LoginResponseModel = response.content;
      if (responseContent.bookingData.memberId && analytics) {
        analytics.identify(responseContent.bookingData.memberId);
      }

      const customer = getCustomer(
        responseContent.bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );

      let newState: GlobalState = {
        ...state,
        availablePosTickets: responseContent.selectTicketsModel,
        bookingData: {
          ...bookingData,
          isUserValidated: responseContent.bookingData.isUserValidated,
          loyaltyCardBalance: responseContent.bookingData.loyaltyCardBalance,
          loyaltyCardPoints: responseContent.bookingData.loyaltyCardPoints,
          loyaltyEmailAddress: responseContent.bookingData.loyaltyEmailAddress,
          loyaltyFullName: responseContent.bookingData.loyaltyFullName,
          loyaltyFirstName: responseContent.bookingData.loyaltyFirstName,
          loyaltyLastName: responseContent.bookingData.loyaltyLastName,
          loyaltyTelephone: responseContent.bookingData.loyaltyTelephone,
          memberLevelId: responseContent.bookingData.memberLevelId,
          memberId: responseContent.bookingData.memberId,
          zipCode: responseContent.bookingData.zipCode,
        },
        bookingFeeStrategy: responseContent.bookingFeeStrategy,
        customer: customer,
        deals: responseContent.deals,
        requestData: responseContent.cookieData.requestData,
        seatsModel:
          step === 0 ? responseContent.selectSeatsModel : state.seatsModel,
        token: responseContent.dataToken,
      };
      if (shouldRedirectToStepOne) {
        newState = {
          ...newState,
          bookingFee: 0,
          bookingFeeTax: 0,
          donation: 0,
          selectedConcessions: {
            ...selectedConcessions,
            list: [],
          },
          selectedDonation: {
            amount: 0,
            isCustomAmount: false,
          },
          selectedGratuity: {
            amount: 0,
            isCustomAmount: false,
            percentage: 0,
          },
          selectedSeats: [],
          ticketTypes: null,
        };
      }

      dispatch(actionCreators.initializeSession(newState));

      if (shouldRedirectToStartTicketing) {
        navigateToStartTicketing(responseContent.cookieData.requestData);
      } else if (step === 0) {
        navigateToNextStep();
      } else if (shouldRedirectToPreviousStep) {
        navigateToPreviousStep();
      } else if (shouldRedirectToStepOne) {
        navigateToStepOne();
      }
    } else {
      setErrorMessage(getContentForError(response.content.peachCode, content));
      setHasError(true);
    }

    turnstile?.resetToken();

    dispatch(actionCreators.setLoading(false));
  };

  const onLoginClick = () => {
    setIsLoginButtonDisabled(true);
    if (setIsDropdownOpen) setIsDropdownOpen(false);
    !validateForm && setValidateForm(true);
    handleRememberUsernameCookie();
    loginMember();
    setIsLoginButtonDisabled(false);
  };

  if (!content) return null;
  return (
    <>
      <Box className='login-details' data-testid='login-details' mx={5}>
        <Form className='mt-1 text-start' noValidate>
          <Form.Group>
            <Form.Label htmlFor='username' tabIndex={0}>
              {resolveLocalisedStringOrDefault(
                formatMessage(messages.usernameLabel),
                locale,
                widget.shape?.usernameLabel
              )}
            </Form.Label>
            <Form.Control
              id='username'
              name='username'
              type='text'
              placeholder={resolveLocalisedStringOrDefault(
                formatMessage(messages.usernamePlaceholder),
                locale,
                widget.shape?.usernamePlaceholder
              )}
              onChange={handleUsernameChange}
              required
              maxLength={50}
              value={loginDetailsState.username}
              isInvalid={
                loginDetailsState.usernameIsValidated &&
                !loginDetailsState.usernameIsValid
              }
              isValid={
                loginDetailsState.usernameIsValidated &&
                loginDetailsState.usernameIsValid
              }
              onBlur={() =>
                setLoginDetailsState({
                  ...loginDetailsState,
                  usernameIsValidated: true,
                })
              }
            />
            <Form.Control.Feedback type='invalid' tabIndex={0}>
              {resolveLocalisedStringOrDefault(
                formatMessage(messages.usernameValidationText),
                locale,
                widget.shape?.usernameErrorMessage
              )}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label htmlFor='password' tabIndex={0}>
              {formatMessage(messages.passwordLabel)}
            </Form.Label>
            <div className='password-container'>
              <Form.Control
                id='password'
                name='password'
                type={showPassword ? 'text' : 'password'}
                placeholder={formatMessage(messages.passwordPlaceholder)}
                onChange={handlePasswordChange}
                required
                maxLength={50}
                value={loginDetailsState.password}
                isInvalid={
                  loginDetailsState.passwordIsValidated &&
                  !loginDetailsState.passwordIsValid
                }
                isValid={
                  loginDetailsState.passwordIsValidated &&
                  loginDetailsState.passwordIsValid
                }
                onBlur={() =>
                  setLoginDetailsState({
                    ...loginDetailsState,
                    passwordIsValidated: true,
                  })
                }
              />
              <button
                className='btn-show-hide'
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
                title={
                  showPassword
                    ? formatMessage(messages.hidePasswordButtonText)
                    : formatMessage(messages.showPasswordButtonText)
                }
                type='button'
              >
                <div className='btn-show-hide-icon'>
                  {showPassword ? <EyeHideSvg /> : <EyeShowSvg />}
                </div>
              </button>
              <Form.Control.Feedback type='invalid' tabIndex={0}>
                {formatMessage(messages.passwordValidationText)}
              </Form.Control.Feedback>
            </div>
          </Form.Group>
        </Form>
        <Flex
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            textAlign: 'left',
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            <CheckBoxButton
              checked={isRememberUsernameChecked}
              onClick={() =>
                setIsRememberUsernameChecked(!isRememberUsernameChecked)
              }
            />
            <span className='tiny'>
              {formatMessage(messages.rememberUsernameText)}
            </span>
          </Flex>
          <Button
            variant='link'
            className='tiny a'
            onClick={() => onForgottenPasswordClick()}
          >
            {formatMessage(messages.forgottenPasswordText)}
          </Button>
        </Flex>
      </Box>
      <CaptchaText marginClass={'mt-3'} paddingClass={'px-4'} />

      {hasError && (
        <ContainedRow styles={{ mx: 2 }}>
          <Box className='warning-container' p={5}>
            <p>{errorMessage}</p>
          </Box>
        </ContainedRow>
      )}
      <Box>
        <ActionButton
          contained
          disabled={isLoginButtonDisabled || !loginDetailsState.isValid}
          mx={5}
          onClick={() => onLoginClick()}
          showIcon
          variant='primary'
        >
          {formatMessage(messages.loginButtonText)}
        </ActionButton>
      </Box>
    </>
  );
};

export default memo(Login);
