import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectCustomer,
  selectGuestSessionToken,
  selectJourneyType,
  selectPhysicalGiftCardRecipient,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const PhysicalGiftCardConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const confirmationData = useSelector(selectConfirmationData);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const customer = useSelector(selectCustomer);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const token = useSelector(selectToken);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_CHECKOUT);
  useConfirmationTracking('GiftCard');

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });

    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);

    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));
    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }
  }, [confirmationData, orderId, fetchData]);

  if (!content || !config) return null;

  const processMode = process.env.NODE_ENV.toLowerCase();
  const isDevelopment = processMode === 'development';
  const buyAnotherGiftCardButtonUrl = isDevelopment
    ? `${'/physicalgiftcards/?circuitId='}${config.circuitId}`
    : '/physicalgiftcards/';
  const contentPhysicalGiftCards = content.physicalGiftCards;

  return (
    <div className='payment text-center' data-testid='payment'>
      <ContainedRow>
        <Heading size={1}>{contentPhysicalGiftCards.stepThreeHeading}</Heading>
        <Heading className='mt-3' size={2}>
          {contentPhysicalGiftCards.confirmationHeading}
        </Heading>
        <RichText
          className='text-start'
          text={contentPhysicalGiftCards.confirmationRichText}
          styles={{ mt: 4 }}
        />

        <Box sx={{ textAlign: 'left', mt: 5 }}>
          <div>
            <span>{physicalGiftCardRecipient.name}</span>
          </div>
          <div className='mt-1'>
            <span>{physicalGiftCardRecipient.addressLineOne}</span>
            <br />
            {physicalGiftCardRecipient.addressLineTwo && (
              <>
                <span>{physicalGiftCardRecipient.addressLineTwo}</span>
                <br />
              </>
            )}
            <span>{physicalGiftCardRecipient.city}</span>
            <br />
            {physicalGiftCardRecipient.state && (
              <>
                <span>{physicalGiftCardRecipient.state}</span>
                <br />
              </>
            )}
            <span>{physicalGiftCardRecipient.postcode}</span>
            <br />
          </div>
          {physicalGiftCardRecipient.message && (
            <Box mt={2}>
              <span>
                {contentPhysicalGiftCards.confirmationMessageIncludedText}
              </span>
            </Box>
          )}
        </Box>
      </ContainedRow>
      <ContainedRow>
        <Heading className='mt-4' size={2}>
          {contentPhysicalGiftCards.confirmationAdditionalHeading}
        </Heading>
        <RichText
          text={contentPhysicalGiftCards.confirmationAdditionalRichText}
          styles={{ mt: 4 }}
        />
      </ContainedRow>
      <ActionButton
        contained
        disabled={false}
        mt={4}
        mx={5}
        onClick={() => (window.location.href = currentCinemaHomeUrl)}
        showIcon={true}
        sticky={false}
        variant='primary'
      >
        {contentPhysicalGiftCards.goBackToWebsiteButtonText}
      </ActionButton>

      {config.giftCardsPurchase.showBuyAnotherGiftCardButton && (
        <ActionButton
          contained
          disabled={false}
          mt={0}
          mx={5}
          onClick={() =>
            (window.location.href = `${buyAnotherGiftCardButtonUrl}`)
          }
          showIcon={true}
          sticky={false}
          variant='secondary'
        >
          {contentPhysicalGiftCards.buyAnotherGiftCardButtonText}
        </ActionButton>
      )}
    </div>
  );
};

export default PhysicalGiftCardConfirmation;
