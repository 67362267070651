/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { getDisplayDateAndTime, timeFormat } from '../../../services/Helpers';
import {
  selectSelectedSeasonPassSessions,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';

const CartSummarySeasonPassSessions: React.FC = () => {
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const sessionsQuantity = selectedSeasonPassSessions.length;

  if (sessionsQuantity === 0) {
    return null;
  }

  return (
    <div className='cart-summary-row'>
      {selectedSeasonPassSessions.map((s, i) => {
        const movieTitle = s.movie.title;
        const dateDisplay = getDisplayDateAndTime(
          moment(s.session.startDateTime).toDate(),
          selectedLanguageCulture
        ).displayDate.slice(0, -5);
        const selectedTimeDisplay = moment(s.session.startDateTime)
          .locale(selectedLanguageCulture)
          .format(timeFormat(selectedLanguageCulture));

        return (
          <Flex
            key={s.session.sessionId}
            sx={{
              mb: i !== selectedSeasonPassSessions.length - 1 ? 2 : 0,
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <Box sx={{ textWrap: 'balance' }}>
                <b>{`${movieTitle}`}</b>
              </Box>
              <Box sx={{ textWrap: 'balance', pl: 4 }}>
                <span sx={{ pr: 2 }}>{`• ${selectedTimeDisplay}`}</span>
                <span>{dateDisplay}</span>
              </Box>
            </Box>
            <Flex
              sx={{
                justifyContent: 'flex-end',
                textAlign: 'right',
                pl: 2,
                flexWrap: 'wrap',
              }}
            >
              {s.seats.length > 0 ? s.seats.join(', ') : '-'}
            </Flex>
          </Flex>
        );
      })}
    </div>
  );
};

export default CartSummarySeasonPassSessions;
