/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import moment from 'moment';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as StepPaths from '../../../constants/stepPaths';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import {
  handleExternalLogInClick,
  handleRegisterClick,
} from '../../../services/LoyaltyHelpers';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectJourneyTypeConfig,
  selectLoginUrl,
  selectRegisterUrl,
  selectSource,
} from '../../../store/Selectors';
import { ReactComponent as CloseXSvg } from '../../../svgs/closeX.svg';
import ActionButton from '../actionbutton/ActionButton';
import RichText from '../richtext/RichText';

const LoyaltyPromo: React.FC = () => {
  const [cookies, setCookies] = useCookies();

  const { pathname } = useLocation();
  const boostNavigate = useBoostNavigate();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const source = useSelector(selectSource);
  const registerUrl = useSelector(selectRegisterUrl);
  const loginUrl = useSelector(selectLoginUrl);

  const [showLoyaltyPromoArea, setShowLoyaltyPromoArea] = useState(true);

  const handleCloseButtonClick = () => {
    const cookieOptions = {
      path: '/',
      expires: moment().add(5, 'y').toDate(),
      secure: true,
      sameSite: true,
    };
    setShowLoyaltyPromoArea(false);
    setTimeout(() => {
      setCookies('hideLoyaltyPromoArea', true, cookieOptions);
    }, 1000);
  };

  const handleLoginButtonClick = () => {
    if (config.signIn.isLoginWithinBoostEnabled) {
      if (journeyTypeConfig.isConcessionsOnlyJourney) {
        boostNavigate.navigateToPath('/login', { appendQueryString: true });
      } else {
        boostNavigate.navigateToStep(0, {
          appendQueryString: true,
          appendCinemaAndSessionIdsFromBookingData: true,
        });
      }
    } else {
      handleExternalLogInClick(
        journeyTypeConfig,
        bookingData,
        source,
        loginUrl
      );
    }
  };

  if (!config || !content) return null;

  const signInOrRegisterIsEnabledSomewhere =
    config.signIn.allowSignIn ||
    config.currentCinema?.allowSignIn ||
    config.signIn.displayRegisterButton;

  const hideLoyaltyPromo =
    !config.showLoyaltyPromoArea || cookies.hideLoyaltyPromoArea;

  const pathNameIsUnsuitable =
    pathname.includes(`/${StepPaths.CONFIRMATION}`) ||
    pathname.includes(`/${StepPaths.LANDING}`) ||
    pathname.includes(`/${StepPaths.LOGIN}`) ||
    pathname.includes(`/${StepPaths.SIGNUP}`) ||
    pathname.startsWith(`/${StepPaths.ERROR}`) ||
    pathname.startsWith(`/${StepPaths.REFUND}`) ||
    pathname.startsWith(`/${StepPaths.WARNING}`);

  const shouldHidePromo =
    !signInOrRegisterIsEnabledSomewhere ||
    hideLoyaltyPromo ||
    bookingData?.isUserValidated ||
    pathNameIsUnsuitable;

  if (shouldHidePromo) return null;

  return (
    <Collapse className='my-3 contained' in={showLoyaltyPromoArea}>
      <div
        className='loyalty-promo pb-1 mb-3'
        data-testid='loyalty-promo'
        sx={{
          borderBottom: 'bodySeparator',
          '.hide-button': {
            color: 'primary',

            svg: {
              fill: 'mostReadableOnWebsiteBackground',
            },

            '&:hover': {
              color: 'mostReadableOnWebsiteBackground',
            },
          },
        }}
      >
        <Row className='contained'>
          <Col className='d-flex justify-content-center'>
            <Button
              className='hide-button small d-flex align-items-center'
              data-testid='hide-button'
              onClick={() => handleCloseButtonClick()}
              variant='link'
            >
              {content.loyaltyPromoCloseButtonText}
              <CloseXSvg className='icon' />
            </Button>
          </Col>
        </Row>
        <Row className='contained'>
          <Col className='d-flex justify-content-center'>
            <RichText
              className='text-center'
              text={content.loyaltyPromoRichText}
            />
          </Col>
        </Row>
        <Row className='contained'>
          <Col xs={{ span: 6, offset: 3 }}>
            <ActionButton
              onClick={() => handleRegisterClick(registerUrl)}
              contained
              mt={3}
              mb={0}
              variant='secondary'
            >
              {content.loyaltyPromoRegisterButtonText}
            </ActionButton>
          </Col>
        </Row>
        <Row className='contained'>
          <Col className='d-flex justify-content-center'>
            <p className='my-1 tiny'>
              {content.loyaltyPromoLoginText}{' '}
              <Button
                className='a b tiny'
                data-testid='login-button'
                onClick={() => handleLoginButtonClick()}
                variant='link'
              >
                {content.loyaltyPromoLoginButtonText}
              </Button>
            </p>
          </Col>
        </Row>
      </div>
    </Collapse>
  );
};

export default LoyaltyPromo;
