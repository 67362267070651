/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Box, Flex } from 'theme-ui';

import ActionButtonCartSummary from './ActionButtonCartSummary';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { ReactComponent as IconArrowSvg } from '../../../svgs/iconArrow.svg';
import WarningMessage from '../warningmessage/WarningMessage';

interface Props {
  contained?: boolean;
  disabled?: boolean;
  modal?: boolean;
  mx?: number;
  mt?: number;
  mb?: number;
  onClick?: React.MouseEventHandler;
  showIcon?: boolean;
  showWarningMessage?: boolean;
  sticky?: boolean;
  stickyMobileDesktop?: boolean;
  warningMessage?: string;
  warningTitle?: string;
  variant: string;
  iconReversed?: boolean;
  showCartSummaryButtonOnMobile?: boolean;
  classNameContainer?: string;
}

const ActionButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  contained,
  disabled,
  modal,
  mx,
  mt,
  mb,
  onClick,
  showIcon,
  showWarningMessage,
  sticky,
  stickyMobileDesktop,
  warningMessage,
  warningTitle,
  variant,
  iconReversed,
  showCartSummaryButtonOnMobile,
  classNameContainer,
}) => {
  const { isLargeScreenWidth } = useScreenWidth();

  const shouldAlwaysBeSticky =
    showCartSummaryButtonOnMobile && !isLargeScreenWidth;

  return (
    <Flex
      className={classnames(
        (shouldAlwaysBeSticky || sticky) && 'sticky-button',
        (shouldAlwaysBeSticky || stickyMobileDesktop) &&
          'sticky-button-mobile-desktop'
      )}
      sx={{
        '&.sticky-button, &.sticky-button-mobile-desktop': {
          backgroundColor: 'websiteBackground',
          '> div': {
            borderTop: 'bodySeparator',
          },
        },
        button: {
          backgroundColor:
            variant === 'primary'
              ? 'primaryButtonBackground'
              : 'secondaryButtonBackground',
          borderColor: variant === 'primary' ? 'primary' : 'accent',
          color:
            variant === 'primary'
              ? 'primaryButtonColor'
              : 'secondaryButtonColor',
          '& svg': {
            fill:
              variant === 'primary'
                ? 'primaryButtonColor'
                : 'secondaryButtonColor',
          },
          '&:hover:enabled, &:focus:enabled, &:focus:hover:enabled': {
            backgroundColor:
              variant === 'primary'
                ? 'primaryButtonBackgroundHover'
                : 'secondaryButtonBackgroundHover',
            borderColor: variant === 'primary' ? 'accent' : 'primary',
            color:
              variant === 'primary'
                ? 'primaryButtonColorHover'
                : 'secondaryButtonColorHover',
            transition:
              'background-color 0.1s ease-out, border-color 0.1s ease-out, color 0.1s ease-out;',
            '& svg': {
              fill:
                variant === 'primary'
                  ? 'primaryButtonColorHover'
                  : 'secondaryButtonColorHover',
            },
          },
        },
        width: '100%',
      }}
    >
      <Box
        className={classnames(contained && 'contained')}
        sx={{ flexGrow: 1 }}
      >
        <div
          className={classnames(
            'action-button-container',
            classNameContainer && classNameContainer
          )}
          sx={{
            mx: mx === undefined ? 0 : mx,
            mt: mt === undefined ? 4 : mt,
            mb: mb === undefined ? 4 : mb,
          }}
        >
          {showWarningMessage && (
            <WarningMessage
              warningTitle={warningTitle}
              warningMessage={warningMessage}
            />
          )}
          <Flex sx={{ justifyContent: 'center' }}>
            {showCartSummaryButtonOnMobile && !isLargeScreenWidth && (
              <ActionButtonCartSummary variant={variant} />
            )}
            <button
              sx={{ width: '100%' }}
              data-testid='action-button'
              className={classnames(
                'action-button tiny',
                modal && 'btn-modal',
                showIcon &&
                  'btn-large d-flex align-items-center justify-content-between',
                variant
              )}
              onClick={onClick}
              disabled={disabled}
              type='button'
            >
              {children}
              {showIcon && (
                <IconArrowSvg
                  className={classnames(
                    iconReversed ? 'icon-arrow-reverse' : 'icon-arrow',
                    'd-flex align-items-center'
                  )}
                  data-testid='action-button-icon'
                />
              )}
            </button>
          </Flex>
        </div>
      </Box>
    </Flex>
  );
};

export default ActionButton;
