import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';

import VoucherItem from './VoucherItem';

import { Concession } from '../../../@types/modelTypes';
import { selectContent, selectEVouchersGroup } from '../../../store/Selectors';

const VoucherKiosk: React.FC = () => {
  const content = useSelector(selectContent);
  const concessionGrouping = useSelector(selectEVouchersGroup);

  return (
    <Row className='mt-4 px-2 px-md-0'>
      <Col className='tab-content'>
        <Row className='header-row py-2 d-none d-md-flex'>
          <Col xs={6} className='text-start'>
            {content.kiosk.itemsLabel}
          </Col>
          <Col xs={4} className='text-center'>
            {content.kiosk.qtyLabel}
          </Col>
          <Col xs={2} className='text-end'>
            {content.kiosk.priceLabel}
          </Col>
        </Row>

        {concessionGrouping?.items?.map((concession: Concession) => (
          <VoucherItem
            {...concession}
            defaultImage={content.vouchers.defaultImage}
            key={concession.id}
          />
        ))}
      </Col>
    </Row>
  );
};

export default VoucherKiosk;
