import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { selectCurrentCinema } from '../../../store/Selectors';

const Address: React.FC = () => {
  const currentCinema = useSelector(selectCurrentCinema);

  return (
    <Box data-testid='address' className='contained text-start' px={3}>
      <p>{currentCinema.title}</p>
      <p>{currentCinema.address1}</p>
      <p>
        {currentCinema.city}
        {', '}
        {currentCinema.stateName} {currentCinema.zipCode}
      </p>
    </Box>
  );
};

export default Address;
