import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectDeals } from '../../../store/Selectors';
import Heading from '../heading/Heading';
import RichText from '../richtext/RichText';

const DealsIntroduction: React.FC = () => {
  const deals = useSelector(selectDeals);

  if (!deals || !deals.length) {
    return null;
  }

  return (
    <div className='header text-center mt-4'>
      {deals.map((deal, index) => {
        return (
          <Row key={`deal_introduction_${index}`} className='banner py-2'>
            <Col xs={12} className='contained'>
              <Heading className='text-center' size={2}>
                {deal.title}
              </Heading>
              <RichText
                text={deal.description}
                className='text-center'
                styles={{ mt: 2 }}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default DealsIntroduction;
