import React from 'react';

import { useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../constants';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContentCartSummary,
  selectCurrencyConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectJourneyTypeConfig,
  selectRefundTotalInCents,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';

interface Props {
  hideTotal?: boolean;
  isRefund?: boolean;
}

const CartSummaryTotal: React.FC<Props> = ({ hideTotal, isRefund }) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const refundTotalInCents = useSelector(selectRefundTotalInCents);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  const getTotalByContext = () => {
    if (isRefund) {
      return refundTotalInCents;
    } else {
      return grandTotalWithDiscount;
    }
  };
  const contextualGrandTotal = getTotalByContext();

  if (
    hideTotal === true ||
    (journeyTypeConfig.type === JOURNEY_TYPES.LOYALTY_SIGNUP_TIERED &&
      contextualGrandTotal < 100)
  )
    return null;

  const priceToDisplay =
    !selectedSeasonPass?.sessions.length &&
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS
      ? '-'
      : displayPrice(contextualGrandTotal, currencyConfig);

  return (
    <div
      className='cart-summary-row cart-summary-row-flex bottom-border'
      data-testid='cs-total'
    >
      <span className='h1' style={{ textTransform: 'uppercase' }}>
        {contentCartSummary.totalLabel}
      </span>
      <span className='h1'>{priceToDisplay}</span>
    </div>
  );
};

export default CartSummaryTotal;
