import {
  Error,
  Customer,
  GiftCardRecipient,
  PhysicalGiftCardRecipient,
} from '../@types/modelTypes';
import { JOURNEY_TYPES } from '../constants';

export const noError: Error = {
  show: false,
  message: '',
  peachCode: 0,
  continueButtonText: '',
  title: '',
};
const emptyCustomer: Customer = {
  name: '',
  nameIsValid: false,
  email: '',
  emailIsValid: false,
  confirmEmail: '',
  confirmEmailIsValid: false,
  telephone: '',
  telephoneIsValid: false,
  isValid: false,
};
const emptyGiftcardRecipient: GiftCardRecipient = {
  name: '',
  nameIsValid: false,
  nameIsValidated: false,
  email: '',
  emailIsValid: false,
  emailIsValidated: false,
  message: '',
  messageIsValid: true,
  messageIsValidated: false,
  isValid: false,
  useSameDetailsForBilling: false,
};

const emptyVoucherRecipient: GiftCardRecipient = {
  name: '',
  nameIsValid: false,
  nameIsValidated: false,
  email: '',
  emailIsValid: false,
  emailIsValidated: false,
  message: '',
  messageIsValid: true,
  messageIsValidated: false,
  isValid: false,
  useSameDetailsForBilling: false,
};

const emptyPhysicalGiftCardRecipient: PhysicalGiftCardRecipient = {
  name: '',
  nameIsValid: false,
  nameIsValidated: false,
  message: '',
  messageIsValid: true,
  messageIsValidated: false,
  addressLineOne: '',
  addressLineOneIsValid: false,
  addressLineOneIsValidated: false,
  addressLineTwo: '',
  addressLineTwoIsValid: true,
  addressLineTwoIsValidated: false,
  city: '',
  cityIsValid: false,
  cityIsValidated: false,
  postcode: '',
  postcodeIsValid: false,
  postcodeIsValidated: false,
  state: '',
  stateIsValid: false,
  stateIsValidated: false,
  isValid: false,
};

export const initialState = {
  appliedDeals: [],
  appliedGiftCards: [],
  appliedVouchers: [],
  availablePosTickets: null,
  bookingData: null,
  bookingFee: 0,
  bookingFeeStrategy: null,
  bookingFeeTax: 0,
  token: '',
  cartSummary: null,
  isCartSummaryOpen: false,
  concessions: null,
  concessionsAdded: false,
  concessionsJourneyParentBookingRef: '',
  config: null,
  confirmationData: null,
  consent: undefined,
  content: null,
  countdownEndTime: null,
  countdownExpired: false,
  customer: emptyCustomer,
  dataLayerPopulated: false,
  dazzler: null,
  dazzlerSteps: null,
  deals: [],
  dealsInTicketsStep: [],
  deliveryItemId: undefined,
  deliveryWindows: [],
  device: '',
  didomiReady: false,
  donation: 0,
  donationRoundUp: 0,
  embargoData: null,
  error: noError,
  giftCardRecipient: emptyGiftcardRecipient,
  giftCardsAdded: false,
  grandTotalWithDiscount: undefined,
  gratuityInCents: 0,
  gratuityLimitInCents: 0,
  guestMarketing: null,
  guestNewsletterSignUp: false,
  hasVoucherApplied: false,
  hostedPayments: {},
  isHostedPaymentInProgress: false,
  imageProcessorUrl: null,
  imageProcessorContainer: null,
  isUserValidated: false,
  journeyType: JOURNEY_TYPES.TICKET_FIRST,
  kioskSubStep: 'fab',
  loading: false,
  loyaltyRecognitionNumber: null,
  loyaltyRedemptionRewards: 0,
  maxRetriesExceeded: false,
  payment: null,
  physicalGiftCardRecipient: emptyPhysicalGiftCardRecipient,
  queryString: '',
  refundData: null,
  requestData: '',
  seatsModel: null,
  selectedConcessions: {
    list: [],
  },
  selectedGiftCards: {
    list: [],
  },
  selectedDeliveryWindow: null,
  deliveryPreference: null,
  selectedDonation: { isCustomAmount: false, amount: 0 },
  selectedGratuity: { isCustomAmount: false, amount: 0, percentage: 0 },
  selectedLanguageCulture: 'en-US',
  selectedPaymentProvider: null,
  selectedSeats: [],
  sendAnalytics: false,
  showtimes: undefined,
  source: '',
  step: 1,
  termsAndConditionsChecked: false,
  ticketTypes: null,
  hasThirdPartyTicketTypes: false,
  totalDiscount: 0,
  voucherRecipient: emptyVoucherRecipient,
  appliedCeaCards: [],
  appliedMemberCards: [],
  turnstileConfig: null,
  recaptchaConfig: null,
  ticketCredits: undefined,
  ticketCreditsAvailable: undefined,
  registerForCarParkingEncryptedToken: '',
  selectedSeasonPass: {
    sessions: [],
  },
  validatedCardPaymentPromoBankCards: [],
};
