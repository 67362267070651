import React, { useEffect, createRef, useState } from 'react';

import { Col, Form, Overlay, Row, Tooltip } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import VoucherPaymentForm from './VoucherPaymentForm';

import { PEACH_CODES } from '../../../../constants';
import { useRecaptcha } from '../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { usePrevious } from '../../../../hooks/usePrevious';
import { getContentForError } from '../../../../services/PeachErrorResolver';
import backend from '../../../../services/RestUtilities';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedGiftCards,
  selectAppliedVouchers,
  selectBankCardAmount,
  selectContent,
  selectCustomer,
  selectToken,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import RichText from '../../richtext/RichText';

interface Props {
  isShown: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  isPageValidated: boolean;
}

const VoucherPayment: React.FC<Props> = ({
  isShown,
  handleValidatePage,
  setPaymentFormVisibility,
  isPageValidated,
}) => {
  const dispatch = useDispatch();
  const prevIsShown = usePrevious(isShown);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const appliedVouchers = useSelector(selectAppliedVouchers);
  const appliedGiftCards = useSelector(selectAppliedGiftCards);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const token = useSelector(selectToken);

  const [isFormValid, setIsFormValid] = useState(false);
  const [voucherInputValue, setVoucherInputValue] = useState('');
  const [voucherError, setVoucherError] = useState(false);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  const voucherCoversFullPayment =
    bankCardAmount === 0 && appliedGiftCards.length === 0;

  useEffect(() => {
    setIsFormValid(
      !appliedVouchers?.find((x) => !x.voucherCode) &&
        !appliedVouchers?.find((x) => !x.totalDiscount) &&
        !!customer?.isValid
    );
  }, [appliedVouchers, customer]);

  useEffect(() => {
    if (isShown !== prevIsShown && !isShown) {
      !voucherCoversFullPayment && setPaymentFormVisibility(true);
    }
  }, [
    isShown,
    prevIsShown,
    dispatch,
    setPaymentFormVisibility,
    voucherCoversFullPayment,
  ]);

  const voucherCodeInput = createRef<HTMLInputElement>();

  const handleApplyVoucher = async () => {
    const voucherCode = voucherCodeInput?.current?.value ?? '';
    setVoucherError(false);

    if (!voucherCode) return;
    dispatch(actionCreators.setLoading(true));

    const response = await backend.post(
      'api/Voucher/ApplyVoucher/' + voucherCode,
      token
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucher = {
          voucherCode: voucherCode,
          totalDiscount: responseContent.totalDiscount,
          totalFee: responseContent.totalFee,
        };
        dispatch(actionCreators.addVoucher(voucher));
        dispatch(actionCreators.setHasVoucherApplied(true));
        dispatch(
          actionCreators.setGrandTotalWithDiscount(
            responseContent.totalFee,
            responseContent.totalDiscount
          )
        );
        setVoucherInputValue('');
      } else {
        setVoucherError(true);
      }
    } else {
      dispatch(
        actionCreators.setError(
          getContentForError(PEACH_CODES.unknownError, content),
          PEACH_CODES.unknownError
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleMakeVoucherPayment = async () => {
    if (!isFormValid || !executeRecaptcha) return;

    dispatch(
      actionCreators.submitMakePayment({
        executeRecaptcha,
        makePaymentModelOverrideProps: {},
        turnstile,
        recaptcha,
      })
    );
  };

  const handleMakeVoucherPaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    handleMakeVoucherPayment();
  };

  const handleTooltip = (showTooltip: boolean) => {
    if (voucherCoversFullPayment) {
      setShowInfoTooltip(showTooltip);
    }
  };

  return (
    <div className='giftcard-payment'>
      <RichText text={content.payment.voucherEnterRichText} />
      <Form className='mt-1'>
        <Row className='g-0'>
          <Col className='pe-2'>
            <Overlay
              placement='top'
              show={showInfoTooltip}
              target={voucherCodeInput}
            >
              <Tooltip
                data-testid='custom-amount-tooltip'
                id='maxAmountLimitReached'
              >
                {content.payment.voucherInputTooltipText}
              </Tooltip>
            </Overlay>
            <Form.Control
              className='spaced-letters'
              onChange={(e) =>
                setVoucherInputValue(e.currentTarget.value.replace(/\s+/g, ''))
              }
              id='giftCardNumber'
              name='giftCardNumber'
              type='text'
              onFocus={() => handleTooltip(true)}
              onBlur={() => handleTooltip(false)}
              placeholder={content.payment.voucherRedemptionPlaceholder}
              ref={voucherCodeInput}
              value={voucherInputValue}
            />
          </Col>
          <Col xs={'auto'}>
            <ActionButton
              disabled={appliedVouchers.length > 0}
              onClick={handleApplyVoucher}
              variant='secondary'
              mb={0}
              mt={0}
            >
              {content.payment.voucherApplyButtonText}
            </ActionButton>
          </Col>
        </Row>
      </Form>
      {voucherError && (
        <div className='info-container mt-2 p-2 text-center'>
          <p>{content.tickets.voucherErrorText}</p>
        </div>
      )}
      <VoucherPaymentForm
        isFormValid={isFormValid}
        voucherCoversFullPayment={voucherCoversFullPayment}
        setPaymentFormVisibility={setPaymentFormVisibility}
        isPageValidated={isPageValidated}
        handleMakeVoucherPaymentClick={handleMakeVoucherPaymentClick}
      />
    </div>
  );
};

export default VoucherPayment;
