import React, { useState, useEffect } from 'react';

import moment, { Moment } from 'moment';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getDisplayCountdown } from '../../../services/Helpers';
import { getTicketingStartJourneyRoute } from '../../../services/JourneyService';
import {
  selectBookingData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectEmbargoData,
  selectQueryString,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';

interface TimeLeft {
  totalDays: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Embargo: React.FC = () => {
  const navigate = useNavigate();

  const content = useSelector(selectContent);
  const embargoData = useSelector(selectEmbargoData);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const bookingData = useSelector(selectBookingData);
  const queryString = useSelector(selectQueryString);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(null);

  const handleBackClick = () => {
    window.location.href = currentCinemaHomeUrl;
  };

  const navigatePath = getTicketingStartJourneyRoute(bookingData);

  useEffect(() => {
    const targetDate: Moment = moment(embargoData?.usersEmbargoDate);
    const getLocalDuration = () => {
      let ms = targetDate.diff(moment.utc());
      if (ms <= 0) {
        ms = 0;
      }
      return moment.duration(ms);
    };

    const updateTimeLeft = () => {
      const duration = getLocalDuration();
      const timeLeft: TimeLeft = {
        totalDays: duration.asDays(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
      setTimeLeft(timeLeft);
    };

    const timer = setTimeout(() => {
      const duration = getLocalDuration().asMilliseconds();
      if (duration <= 0) {
        navigate({
          pathname: navigatePath,
          search: queryString,
        });
        clearTimeout(timer);
        return;
      }

      updateTimeLeft();
    }, 1000);

    return () => clearTimeout(timer); // Clear timeout if the component is unmounted
  });

  if (!content || !embargoData) return null;

  const localDisplayDate = moment(embargoData.embargoDateLocal)
    .locale(selectedLanguageCulture)
    .format('LLLL');

  const localCountdown = getDisplayCountdown(selectedLanguageCulture);

  return (
    <div className='login' data-testid='embargo'>
      <ContainedRow classNameCol='text-center'>
        <ShowtimeHero />
      </ContainedRow>
      <ContainedRow classNameCol='text-center mt-3'>
        <Heading size={2}>{content.embargo.title}</Heading>
        <RichText text={content.embargo.embargoRichText} />
        {!embargoData.hideCountdown && (
          <>
            <div>
              <p>{content.embargo.onSaleFromText}</p>
            </div>
            <div className='fw-bold local-date mt-4'>
              {`${localDisplayDate} (${embargoData.timezoneDaylight}).`}
            </div>
            {timeLeft?.totalDays != null && timeLeft.totalDays <= 1 && (
              <div className='text-center timer-container mt-4'>
                <Row className='g-0'>
                  <Col>{localCountdown.hrs}</Col>
                  <Col></Col>
                  <Col>{localCountdown.mins}</Col>
                  <Col></Col>
                  <Col>{localCountdown.secs}</Col>
                </Row>
                <Row className='g-0 values fw-bold'>
                  <Col>{String(timeLeft.hours).padStart(2, '0')}</Col>
                  <Col>:</Col>
                  <Col>{String(timeLeft.minutes).padStart(2, '0')}</Col>
                  <Col>:</Col>
                  <Col>{String(timeLeft.seconds).padStart(2, '0')}</Col>
                </Row>
              </div>
            )}
          </>
        )}

        <ActionButton
          contained
          onClick={handleBackClick}
          showIcon
          mx={5}
          variant='primary'
        >
          {content.embargo.buttonText}
        </ActionButton>
      </ContainedRow>
    </div>
  );
};

export default Embargo;
