/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import HeaderContainer from './HeaderContainer';
import HeaderDealsApplied from './HeaderDealsApplied';
import HeaderLogin from './HeaderLogin';

import LoyaltyPromo from '../loyaltypromo/LoyaltyPromo';

const Header: React.FC = () => {
  return (
    <>
      <header
        className='header text-center'
        data-testid='header'
        sx={{ marginX: -3 }}
      >
        <HeaderLogin />
        <HeaderDealsApplied />
        <HeaderContainer />
      </header>
      <LoyaltyPromo />
    </>
  );
};

export default Header;
