/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Row, Col, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedCardPaymentPromoBankCard,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectAppliedCardPaymentPromoBankCards,
  selectContent,
  selectCurrencyConfig,
  selectTicketTypes,
} from '../../../store/Selectors';

interface AppliedCardPaymentPromoBankCardsProps {
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
}

const AppliedCardPaymentPromoBankCards: React.FC<
  AppliedCardPaymentPromoBankCardsProps
> = ({ handleTicketButtonClick }) => {
  const content = useSelector(selectContent);
  const appliedCardPaymentPromoBankCards: ValidatedCardPaymentPromoBankCard[] =
    useSelector(selectAppliedCardPaymentPromoBankCards);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const ticketTypes = useSelector(selectTicketTypes);

  const cardHasMatchingTicketWithQuantity = (): boolean => {
    return appliedCardPaymentPromoBankCards.some(
      (card: ValidatedCardPaymentPromoBankCard) => {
        const matchingTicketType = ticketTypes?.ticketTypeModels?.find(
          (ticketType: TicketTypeModel) => ticketType.id === card.usedTicketId
        );
        return matchingTicketType && matchingTicketType.quantity > 0;
      }
    );
  };

  if (
    !ticketTypes?.ticketTypeModels?.length ||
    !cardHasMatchingTicketWithQuantity
  )
    return;

  const handleRemoveAppliedBankCard = (bankCardNo: string) => {
    const appliedBankCard = appliedCardPaymentPromoBankCards?.find(
      (usedBankCard) => usedBankCard.bankCardNumber === bankCardNo
    );
    const ticketIdToRemove = appliedBankCard?.usedTicketId;
    if (ticketIdToRemove) {
      handleTicketButtonClick(ticketIdToRemove, 'remove');
    }
  };

  const formatBankCardNumber = (bankCardNumber: string): string => {
    return `${bankCardNumber.slice(0, 4)} ${bankCardNumber.slice(4)}`;
  };

  const getMatchingTicketType = (
    usedTicketId: string
  ): TicketTypeModel | undefined => {
    const matchingTicketTypeModel = ticketTypes?.ticketTypeModels.find(
      (ticketType: TicketTypeModel) => ticketType.id === usedTicketId
    );
    return matchingTicketTypeModel;
  };

  return (
    <div data-testid='applied-bank-promo-cards'>
      <div
        className='pt-4 pb-2 text-center h3'
        sx={{
          borderTop: 'bodySeparator',
          borderBottom: 'bodySeparator',
        }}
      >
        {content.tickets.cardPaymentPromoTicketsAdditionalTicketsHeading}
      </div>
      {appliedCardPaymentPromoBankCards?.map(
        (card: ValidatedCardPaymentPromoBankCard) => (
          <Row
            className='pt-2 pb-2 contained d-flex justify-content-between'
            key={card.usedTicketId}
            sx={{
              borderBottom: 'bodySeparator',
            }}
          >
            <Col xs={6} md={8} className='text-start m-0 p-0'>
              <div>
                {getMatchingTicketType(card.usedTicketId)?.displayName}
                <span> x </span>
                <span className='quantity'>
                  {getMatchingTicketType(card.usedTicketId)?.quantity}
                </span>
              </div>
              <div className='tiny'>
                <span>
                  {getMatchingTicketType(card.usedTicketId)?.longDescription}
                  {` • `}
                </span>
                <span>
                  {content.tickets.cardPaymentPromoTicketsCardNumberText}{' '}
                  {card?.bankCardNumber &&
                    formatBankCardNumber(card.bankCardNumber)}
                </span>
              </div>
            </Col>
            <Col
              xs={3}
              md={2}
              className='float-start d-flex justify-content-center align-items-center m-0 p-0'
            >
              <Button
                onClick={() =>
                  handleRemoveAppliedBankCard(card?.bankCardNumber)
                }
                variant='link'
                size='sm'
              >
                <span className='tiny'>
                  {content.payment.removeButtonText.toUpperCase()}{' '}
                </span>
              </Button>
            </Col>
            <Col
              xs={3}
              md={2}
              className='d-flex align-items-center justify-content-end strong m-0 p-0'
            >
              <span>
                {displayPrice(
                  getMatchingTicketType(card.usedTicketId)?.price ?? 0,
                  currencyConfig
                )}
              </span>
            </Col>
          </Row>
        )
      )}
    </div>
  );
};

export default AppliedCardPaymentPromoBankCards;
