import React, { useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidateCardPaymentPromoBankCardNumberRequestModel,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectContent, selectToken } from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import RichText from '../richtext/RichText';

interface Props {
  orderHasMaxTickets: boolean;
  handleAddTicket: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  ticket: TicketTypeModel;
}

const CardPaymentPromoBankCardInput: React.FC<Props> = ({
  orderHasMaxTickets,
  handleAddTicket,
  ticket,
}) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const dataToken = useSelector(selectToken);
  const turnstile = useTurnstile();

  const [bankCardInput, setBankCardInput] = useState('');
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);

  const validateBankCard = async () => {
    if (!bankCardInput) return;

    const turnstileToken = await turnstile?.getToken();

    const data: ValidateCardPaymentPromoBankCardNumberRequestModel = {
      dataToken,
      ticketId: ticket.id,
      creditCardBin: bankCardInput,
    };

    try {
      const response = await backend.post(
        'api/Tickets/ValidateCardPaymentPromoBankCardNumber',
        data,
        turnstileToken
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        await handleAddTicket(ticket.id, 'add');
        dispatch(
          actionCreators.addCardPaymentPromoBankCard({
            bankCardNumber: bankCardInput,
            usedTicketId: ticket.id,
          })
        );
        setBankCardInput('');
        setFeedback('');
      } else if (
        response.content.peachCode ===
        PEACH_CODES.invalidCardPaymentPromoBankNumber
      ) {
        setFeedback(
          getContentForError(
            PEACH_CODES.invalidCardPaymentPromoBankNumber,
            content
          )
        );
      } else {
        setFeedback(getContentForError(PEACH_CODES.unknownError, content));
      }
    } catch (error) {
      setFeedback(getContentForError(PEACH_CODES.unknownError, content));
    } finally {
      turnstile?.resetToken();
      setDisableApplyButton(false);
    }
  };

  const handleBankCardCheck = async () => {
    if (!bankCardInput) return;
    setDisableApplyButton(true);
    validateBankCard();
  };

  const handleInputChange = (input: string) => {
    setFeedback('');
    const isNumeric = /^\d*$/.test(input);
    if (isNumeric && input.length <= 6) {
      setBankCardInput(input);
    }
  };

  return (
    <div>
      <div className='text-start'>
        <RichText
          text={content.tickets.cardPaymentPromoTicketsCardInputRichText}
        />
      </div>
      <Row className='mt-2 mb-3'>
        <Col xs={8} className='px-1'>
          <Form.Control
            id='bankCardNumber'
            name='bankCardNumber'
            type='text'
            placeholder={
              content.tickets.cardPaymentPromoTicketsCardInputPlaceholderText
            }
            onChange={(e) => handleInputChange(e.target.value)}
            value={bankCardInput}
            disabled={disableApplyButton || orderHasMaxTickets}
            className='spaced-letters'
            maxLength={6}
            data-testid='bank-card-input'
          />
        </Col>
        <Col xs={4} className='ps-0 pe-1'>
          <ActionButton
            onClick={handleBankCardCheck}
            disabled={
              disableApplyButton || orderHasMaxTickets || !bankCardInput
            }
            variant='secondary'
            mb={0}
            mt={0}
          >
            {disableApplyButton ? (
              <ActionButtonSpinner />
            ) : (
              content.checkButtonText
            )}
          </ActionButton>
        </Col>
      </Row>
      {!!feedback && (
        <Box className='warning-container' sx={{ mt: 4, mb: 5, p: 2 }}>
          <p>{feedback}</p>
        </Box>
      )}
    </div>
  );
};

export default CardPaymentPromoBankCardInput;
