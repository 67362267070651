/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import {
  displayPrice,
  handleAddRemoveConcessionDynamicBasket,
  handleUpdateConcessionsDynamicBasket,
} from '../../../services/Helpers';
import {
  findModifiedConcessionMatch,
  findModifiedConcessionItemCost,
  currentGroupQuantity,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectSelectedFaBConcessions,
  selectSelectedDeliveryWindow,
  selectToken,
  selectJourneyTypeConfig,
  selectFandBItemModalData,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  concession: Concession;
  showToast: (message: string) => void;
}

const FandBItemModalQtyButtons: React.FC<Props> = ({
  concession,
  showToast,
}) => {
  const dispatch = useDispatch();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const dataToken = useSelector(selectToken);
  const fandbItemModalData = useSelector(selectFandBItemModalData);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);

  const modalContext = fandbItemModalData.modalContext;
  const concessionIndex = fandbItemModalData.concessionIndex;

  const selectedConcession: Concession | undefined =
    findModifiedConcessionMatch(concession, selectedConcessions);

  const isEditingItemWithoutModifiers =
    selectedConcession &&
    selectedConcession.quantity > 0 &&
    selectedConcession.modifierGroups.length === 0;

  const quantityInCart: number = isEditingItemWithoutModifiers
    ? selectedConcession.quantity
    : 1;

  const [concessionAdded, setConcessionAdded] = useState<boolean>(false);

  const [concessionQuantity, setConcessionQuantity] =
    useState<number>(quantityInCart);

  const quantityOfItemAlreadyInCart = selectedConcession?.quantity;

  const isItemInCartMatchingOriginalQuantity =
    quantityOfItemAlreadyInCart === concessionQuantity;

  const hideTax = config.currentCinema.hideTax;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const minQuantityNotReached = concession.modifierGroups?.some(
    (mg: ModifierGroup) =>
      mg.minimumQuantity > 0 && currentGroupQuantity(mg) < mg.minimumQuantity
  );

  const requiredModifiersCount = concession.modifierGroups.filter(
    (mg: ModifierGroup) => mg.minimumQuantity > 0
  ).length;

  const requiredModifiersSelectedCount = concession.modifierGroups.filter(
    (mg: ModifierGroup) =>
      mg.minimumQuantity > 0 && currentGroupQuantity(mg) >= mg.minimumQuantity
  ).length;

  const resetSelectedConcession = () => {
    setConcessionAdded(true);
    setTimeout(() => {
      setConcessionAdded(false);
      setConcessionQuantity(1);
      concession.modifierGroups.forEach((mg: ModifierGroup) =>
        mg.modifiers.forEach((m: Modifier) => {
          mg.show = false;
          m.quantity = mg.isSmart ? 1 : 0;
        })
      );
    }, 750);
  };

  const handleQuantityChange = async (context: QuantitySelectorContext) => {
    if (context === 'add') {
      setConcessionQuantity(concessionQuantity + 1);
    } else {
      setConcessionQuantity(concessionQuantity - 1);
    }
  };

  const handleConcessionChange = async (
    context: QuantitySelectorContext,
    selectedQuantity?: number
  ) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );

    concessionToChange.quantity = selectedQuantity ?? 1;

    if (context === 'update' && concessionIndex !== undefined) {
      dispatch(
        actionCreators.changeConcessionByIndex(
          concessionToChange,
          concessionIndex,
          true
        )
      );
    } else if (context === 'add') {
      dispatch(actionCreators.addConcessionWithMods(concessionToChange, true));
    } else {
      dispatch(
        actionCreators.removeConcessionWithMods(concessionToChange, true)
      );
    }

    if (config.useDynamicBasket) {
      if (context === 'update') {
        dispatch(actionCreators.setLoading(true));
        await handleUpdateConcessionsDynamicBasket(
          context,
          selectedConcessions.list,
          dataToken,
          journeyTypeConfig.type,
          selectedDeliveryWindow,
          bookingData,
          dispatch,
          content
        );
        dispatch(actionCreators.setLoading(false));
      } else {
        dispatch(actionCreators.setLoading(true));
        await handleAddRemoveConcessionDynamicBasket(
          context,
          concessionToChange,
          dataToken,
          journeyTypeConfig.type,
          selectedDeliveryWindow,
          bookingData,
          dispatch,
          content
        );
        dispatch(actionCreators.setLoading(false));
      }
    }
    resetSelectedConcession();
    setTimeout(() => {
      dispatch(actionCreators.removeFandBItemModalData());
      {
        concessionQuantity > 0 &&
          showToast(
            content.kiosk.buttonAddedText?.replace(
              '##quantity##',
              `<b>${concessionQuantity}${' x '}${
                concessionToChange.description
              }</b>`
            )
          );
      }
    }, 750);
  };

  const renderLocalStateQuantityButtons = () => {
    const priceToDisplay = hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;
    const subTotal =
      (concessionQuantity > 0 ? concessionQuantity : 1) * priceToDisplay;
    let buttonText;
    if (concession.isSoldOut && content.kiosk.soldOutText) {
      buttonText = content.kiosk.soldOutText;
    } else if (
      modalContext === 'update' ||
      (modalContext === 'add' &&
        isEditingItemWithoutModifiers &&
        !isItemInCartMatchingOriginalQuantity)
    ) {
      buttonText =
        concessionQuantity > 0
          ? `${content.kiosk.buttonUpdateText?.replace(
              '##quantity##',
              String(concessionQuantity)
            )} ${
              itemPricing.costIncTax > 0 &&
              displayPrice(subTotal, currencyConfig)
            }`
          : content.kiosk.kioskModalRemove;
    } else if (isEditingItemWithoutModifiers) {
      buttonText = isItemInCartMatchingOriginalQuantity
        ? `${concessionQuantity}${' '}${
            content.kiosk.kioskModalInCartButtonText
          }`
        : `${
            content.kiosk.kioskModalUpdateButtonText
          }${' '}${concessionQuantity}${' : '}${
            itemPricing.costIncTax > 0 && displayPrice(subTotal, currencyConfig)
          }`;
    } else if (concessionAdded) {
      buttonText = content.kiosk.buttonAddedText?.replace(
        '##quantity##',
        String(concessionQuantity)
      );
    } else if (minQuantityNotReached) {
      buttonText = (
        <>
          {content.kiosk.selectRequiredText?.replace(
            '##quantityRequired##',
            String(requiredModifiersCount - requiredModifiersSelectedCount)
          )}
        </>
      );
    } else {
      buttonText = `${content.kiosk.buttonText?.replace(
        '##quantity##',
        String(concessionQuantity)
      )} ${
        itemPricing.costIncTax > 0 && displayPrice(subTotal, currencyConfig)
      }`;
    }

    return (
      <Flex>
        <Flex
          sx={{
            marginRight: 4,
          }}
        >
          <div
            sx={{
              alignItems: 'center',
            }}
          >
            {renderLocalStateButton(
              concessionQuantity === 0 ? 'remove' : 'update',
              modalContext === 'update' ||
                (modalContext === 'add' && isEditingItemWithoutModifiers)
                ? concessionQuantity === 0
                : concessionQuantity === 1
            )}
          </div>
          <div
            sx={{
              alignContent: 'center',
              paddingX: 4,
            }}
          >
            <div data-testid='quantity-text'>{concessionQuantity}</div>
          </div>
          <div
            sx={{
              alignItems: 'center',
            }}
          >
            {renderLocalStateButton(
              'add',
              minQuantityNotReached || concession.isSoldOut
            )}
          </div>
        </Flex>
        <Flex
          sx={{
            flexGrow: 1,

            div: {
              width: '100%',
              margin: 0,

              div: {
                padding: 0,
              },
            },
          }}
        >
          <ActionButton
            data-testid='concession-change-button'
            disabled={
              minQuantityNotReached ||
              concessionAdded ||
              concession.isSoldOut ||
              isItemInCartMatchingOriginalQuantity
            }
            onClick={() =>
              !minQuantityNotReached &&
              handleConcessionChange(
                modalContext === 'add'
                  ? 'add'
                  : modalContext === 'update'
                  ? 'update'
                  : 'remove',
                concessionQuantity
              )
            }
            mt={0}
            mb={0}
            variant={'primary'}
          >
            {buttonText}
          </ActionButton>
        </Flex>
      </Flex>
    );
  };

  const renderLocalStateButton = (
    context: QuantitySelectorContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={concessionAdded ? true : disabled}
        onClick={() => handleQuantityChange(context)}
        size='small'
      />
    );
  };

  return renderLocalStateQuantityButtons();
};

export default FandBItemModalQtyButtons;
