/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid } from 'theme-ui';

import FandBItemImage from './FandBItemImage';

import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectCurrencyConfig,
  selectImageProcessorContainer,
  selectImageProcessorUrl,
} from '../../../store/Selectors';

interface Props {
  concession: Concession;
  showImageColumn: boolean;
}

const FandBItem: React.FC<Props> = ({ concession, showImageColumn }) => {
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);

  const imagePath = concession.image
    ? concession.image.replace('https://', '')
    : null;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const hideTax = config.currentCinema.hideTax;

  const imageUrl: string = imagePath
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imagePath}?height=100`
    : '/images/concession-fallback.png';

  return (
    <Grid
      gap={0}
      columns={['1fr auto']}
      data-testid='f-and-b-item'
      sx={{ marginTop: 6 }}
    >
      <Box>
        <div>
          <Button
            variant='link'
            onClick={() =>
              dispatch(
                actionCreators.setFandBItemModalData(concession, 'add', true)
              )
            }
            sx={{ fontWeight: 'bold' }}
          >
            {concession.description}
          </Button>
        </div>
        <Box sx={{ mt: 1 }}>
          {itemPricing.costIncTax > 0 &&
            displayPrice(
              hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax,
              currencyConfig
            )}
        </Box>
      </Box>
      {showImageColumn && (
        <Box>
          <Button
            variant='link'
            onClick={() =>
              dispatch(
                actionCreators.setFandBItemModalData(concession, 'add', true)
              )
            }
          >
            <FandBItemImage
              concession={concession}
              imageUrl={imageUrl}
              showQuantityButton
            />
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default FandBItem;
