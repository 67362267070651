/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Flex, Image } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import { findTotalQuantityOfSelectedConcessionById } from '../../../services/KioskHelpers';
import { selectSelectedFaBConcessions } from '../../../store/Selectors';

interface Props {
  concession?: Concession;
  imageUrl: string;
  showQuantityButton?: boolean;
}

const FandBItemImage: React.FC<Props> = ({
  concession,
  imageUrl,
  showQuantityButton,
}) => {
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const quantity: number | null =
    (showQuantityButton &&
      concession &&
      selectedConcessions &&
      findTotalQuantityOfSelectedConcessionById(
        concession,
        selectedConcessions
      )) ||
    null;

  return (
    <Flex
      sx={{
        flexShrink: 0,
        margin: showQuantityButton && '8px',
        maxWidth: '250px',
        position: 'relative',
      }}
    >
      <Image src={imageUrl} variant='concessionItem' />
      {showQuantityButton && (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: quantity ? 'websiteBackground' : 'primary',
            border: quantity
              ? 'mostReadableOnWebsiteBackgroundBorder'
              : 'primaryBorder',
            borderRadius: '15px',
            color: quantity
              ? 'mostReadableOnWebsiteBackground'
              : 'mostReadableOnPrimaryBackground',
            fontSize: quantity ? 'small' : '1.25rem',
            height: '26px',
            width: '26px',
            position: 'absolute',
            top: '-8px',
            right: '-8px',
          }}
        >
          {quantity || '+'}
        </Flex>
      )}
    </Flex>
  );
};

export default FandBItemImage;
