import React, { useState } from 'react';

import classNames from 'classnames';
import { Col, Form, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import VoucherTicketType from './VoucherTicketType';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  GroupedTicketTypes,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectTicketTypes,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';

const SUCESSFULLYAPPLIED = 'SUCESSFULLYAPPLIED';
const ALREADYAPPLIED = 'ALREADYAPPLIED';
const NOTRECOGNISED = 'NOTRECOGNISED';
const SERVERERROR = 'SERVERERROR';
const NOTICKETSRETURNED = 'NOTICKETSRETURNED';

interface Props {
  orderHasMaxTickets: boolean;
}

const ThirdPartyVoucherSelector: React.FC<Props> = ({ orderHasMaxTickets }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const ticketTypes = useSelector(selectTicketTypes);
  const token = useSelector(selectToken);
  const allTicketTypes = useSelector(selectTicketTypes);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [showVoucherForm, setShowVoucherForm] = useState(
    ticketTypes
      ? ticketTypes.ticketTypeModels.some((t) => t.voucherCode)
      : false
  );
  const [voucherInput, setVoucherInput] = useState('');
  const selectedSeats = useSelector(selectSelectedSeats);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const isSeatsFirst = journeyTypeConfig.isSeatsFirst;

  if (!ticketTypes) return null;

  const updateTicketTypes = (ticketTypeModels: TicketTypeModel[]) => {
    dispatch(actionCreators.setTicketTypes({ ticketTypeModels }));
  };

  const addVoucher = async (voucherCode: string) => {
    if (!executeRecaptcha) return;

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddVoucherCode',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const unlockedRedemptionTicketIds: string[] = ticketTypes.ticketTypeModels
      .filter((x) => x.isRedemptionTicket)
      .map((x) => x.id);
    const data = {
      voucherCode,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
      unlockedRedemptionTicketIds,
    };
    const response = await backend.post(
      'api/Tickets/ValidateThirdPartyVoucherCode',
      data,
      turnstileToken
    );
    if (response.ok) {
      const responseContent = response.content;

      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucherTicketGroups: GroupedTicketTypes[] =
          response.content.groupedTicketTypes;
        const ticketTypeModels = ticketTypes.ticketTypeModels;

        voucherTicketGroups.forEach((gpt) => {
          gpt.ticketTypeModels.forEach((t) => {
            t.validatedVouchers = [voucherCode];
            t.voucherCode = voucherCode;
            t.voucherGroupMaxQuantity = response.content.quantityLimit;
            t.voucherDisplayName = `${t.displayName} (${voucherCode})`;
            t.isThirdPartyMemberVoucher = true;
            ticketTypeModels.push(t);
          });
        });

        updateTicketTypes(ticketTypeModels);

        setFeedback(SUCESSFULLYAPPLIED);
        setVoucherInput('');
      } else if (responseContent.peachCode === PEACH_CODES.noTicketReturn) {
        setFeedback(NOTICKETSRETURNED);
      } else {
        setFeedback(NOTRECOGNISED);
      }
    } else {
      setFeedback(SERVERERROR);
    }

    turnstile?.resetToken();

    setDisableApplyButton(false);
  };

  const handleVoucherCheck = async () => {
    if (!voucherInput) return;
    setDisableApplyButton(true);
    const exists = ticketTypes.ticketTypeModels.some(
      (item) => item.voucherCode === voucherInput
    );
    if (!exists) {
      addVoucher(voucherInput);
    } else {
      setDisableApplyButton(false);
      setFeedback(ALREADYAPPLIED);
    }
  };

  const handleVoucherClick = (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find(
      (x) => x.id === ticketTypeId && x.voucherCode === voucherCode
    );

    if (!ticketTypeModel) {
      return;
    }

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
    } else {
      ticketTypeModel.quantity += -1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    updateTicketTypes(ticketTypeModels);
  };

  const hasTicketsWithTheSameVoucherAlreadyAdded = (
    voucherCode: TicketTypeModel['voucherCode'],
    ticketMaxQuantity: TicketTypeModel['maxQuantity'],
    voucherGroupMaxQuantity: TicketTypeModel['voucherGroupMaxQuantity']
  ) => {
    const ticketsWithTheSameVoucher = ticketTypes.ticketTypeModels.filter(
      (t) => t.voucherCode === voucherCode
    );

    if (!ticketsWithTheSameVoucher.length) {
      return false;
    }

    const ticketsWithTheSameVoucherSelectedQuantity =
      ticketsWithTheSameVoucher.reduce((a, b) => a + (b.quantity || 0), 0);

    return (
      ticketMaxQuantity === ticketsWithTheSameVoucherSelectedQuantity ||
      ticketsWithTheSameVoucherSelectedQuantity === voucherGroupMaxQuantity
    );
  };
  const shouldDisableAddTicket = (t: TicketTypeModel) => {
    const ticketsInArea = allTicketTypes?.ticketTypeModels.filter(
      (ti: TicketTypeModel) => ti.areaCategoryCode === t.areaCategoryCode
    );

    const selectedTicketsInAreaCount =
      ticketsInArea?.reduce((a, b) => a + (b.quantity * b.nbSeats || 0), 0) ??
      0;

    const selectedSeatsInArea = selectedSeats?.filter(
      (s) => s.areaCategoryCode === t.areaCategoryCode
    );

    return (
      orderHasMaxTickets ||
      !!(t.maxQuantity && t.quantity === t.maxQuantity) ||
      hasTicketsWithTheSameVoucherAlreadyAdded(
        t.voucherCode,
        t.maxQuantity,
        t.voucherGroupMaxQuantity
      ) ||
      (isSeatsFirst &&
        selectedSeatsInArea.length === selectedTicketsInAreaCount) ||
      (isSeatsFirst &&
        selectedSeatsInArea.length < selectedTicketsInAreaCount + t.nbSeats)
    );
  };

  const shouldDisplayTicket = (t: TicketTypeModel) => {
    return (
      t.voucherCode &&
      t.isThirdPartyMemberVoucher &&
      (!isSeatsFirst ||
        selectedSeats.some((x) => x.areaCategoryCode === t.areaCategoryCode))
    );
  };

  return (
    <BorderedCollapse
      closeButtonText={content.payment.closeButtonText}
      heading={content.tickets.thirdPartyMemberTicketsVoucherHeading}
      setShow={setShowVoucherForm}
      show={showVoucherForm}
    >
      <div className='voucher-container' data-testid='voucher-selector'>
        {content.tickets.thirdPartyMemberTicketsVoucherText && (
          <p className='mb-1'>
            {content.tickets.thirdPartyMemberTicketsVoucherText}
          </p>
        )}
        <div className='voucher-selector'>
          <Form>
            <Form.Label
              className={classNames(disableApplyButton && 'disabled')}
            >
              {content.tickets.thirdPartyMemberTicketsVoucherLabel}
            </Form.Label>
            <Row className='g-0'>
              <Col className='pe-2'>
                <Form.Control
                  id='voucherNumber'
                  name='voucherNumber'
                  type='text'
                  placeholder={
                    content.tickets.thirdPartyMemberTicketsVoucherPlaceholder
                  }
                  onChange={(e) => {
                    setVoucherInput(e.target.value);
                  }}
                  value={voucherInput}
                  disabled={disableApplyButton}
                />
              </Col>
              <Col xs={'auto'}>
                <ActionButton
                  onClick={handleVoucherCheck}
                  disabled={disableApplyButton}
                  variant='tertiary'
                  mb={0}
                  mt={0}
                >
                  {disableApplyButton ? (
                    <ActionButtonSpinner />
                  ) : (
                    content.checkButtonText
                  )}
                </ActionButton>
              </Col>
            </Row>
          </Form>
          {feedback && feedback !== SUCESSFULLYAPPLIED && (
            <Box className='warning-container' sx={{ mt: 5, p: 5 }}>
              <p>
                {feedback === ALREADYAPPLIED
                  ? content.tickets
                      .thirdPartyMemberTicketsVoucherAlreadyAppliedText
                  : feedback === NOTRECOGNISED
                  ? content.tickets
                      .thirdPartyMemberTicketsVoucherInvalidVoucherText
                  : feedback === NOTICKETSRETURNED &&
                    content.tickets
                      .thirdPartyMemberTicketsVoucherNoTicketsReturnedText}
              </p>
            </Box>
          )}
          <div className='voucher-list'>
            {ticketTypes?.ticketTypeModels
              ?.filter((t) => shouldDisplayTicket(t))
              ?.map((t) => (
                <VoucherTicketType
                  key={`${t.id}${t.voucherCode}`}
                  ticket={t}
                  onClick={handleVoucherClick}
                  disableAdd={shouldDisableAddTicket(t)}
                  disableRemove={t.quantity === 0}
                  packageTicketsIncludesLabel={
                    content.tickets.packageTicketsIncludesLabel
                  }
                  hideTax={config.currentCinema.hideTax}
                />
              ))}
          </div>
        </div>
      </div>
    </BorderedCollapse>
  );
};

export default ThirdPartyVoucherSelector;
