import React, { useEffect, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isLength } from 'validator';

import { Customer } from '../../../@types/modelTypes';
import { isBansheeSafeEmail, validateZipCode } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
} from '../../../store/Selectors';

interface Props {
  isPageValidated: boolean;
}

const CustomerDetails: React.FC<Props> = ({ isPageValidated }) => {
  const dispatch = useDispatch();

  const bookingData = useSelector(selectBookingData);
  const { currentCinema, payment: paymentConfig } = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);

  const [customerState, setCustomerState] = useState<Customer>({
    name: customer.name ?? '',
    nameIsValid: !!customer.nameIsValid,
    nameIsValidated: false,
    email: customer.email ?? '',
    emailIsValid: !!customer.emailIsValid,
    emailIsValidated: false,
    confirmEmail: customer.confirmEmail ?? '',
    confirmEmailIsValid: !!customer.confirmEmailIsValid,
    confirmEmailIsValidated: false,
    telephone: customer.telephone ?? '',
    telephoneIsValid: !!customer.telephoneIsValid,
    telephoneIsValidated: false,
    zipCode: customer.zipCode ?? '',
    zipCodeIsValid: !!customer.zipCodeIsValid,
    zipCodeIsValidated: false,
    isValid: false,
  });

  const zipCodeMin = currentCinema.isZipCodeRequired ? 1 : 0;

  // sets is validated status on properties
  useEffect(() => {
    if (isPageValidated) {
      setCustomerState((prevState) => ({
        ...prevState,
        nameIsValidated: true,
        emailIsValidated: true,
        confirmEmailIsValidated: true,
        telephoneIsValidated: true,
        zipCodeIsValidated: true,
      }));
    }
  }, [isPageValidated]);

  // if user is logged in sets validation
  useEffect(() => {
    if (bookingData?.isUserValidated) {
      setCustomerState({
        name: customer.name ?? '',
        nameIsValid: !!customer.nameIsValid,
        nameIsValidated: false,
        email: customer.email ?? '',
        emailIsValid: !!customer.emailIsValid,
        emailIsValidated: false,
        confirmEmail: customer.confirmEmail ?? '',
        confirmEmailIsValid: !!customer.confirmEmailIsValid,
        confirmEmailIsValidated: false,
        telephone: customer.telephone ?? '',
        telephoneIsValid: !!customer.telephoneIsValid,
        telephoneIsValidated: false,
        zipCode: customer.zipCode ?? '',
        zipCodeIsValid: !!customer.zipCodeIsValid,
        zipCodeIsValidated: false,
        isValid: false,
      });
    }
  }, [
    bookingData?.isUserValidated,
    customer.confirmEmail,
    customer.confirmEmailIsValid,
    customer.email,
    customer.emailIsValid,
    customer.name,
    customer.nameIsValid,
    customer.telephone,
    customer.telephoneIsValid,
    customer.zipCode,
    customer.zipCodeIsValid,
  ]);

  const handleCustomerChange = (nextCustomerState: Customer) => {
    setCustomerState(nextCustomerState);
    dispatch(actionCreators.setCustomerDetails(nextCustomerState));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value.trimStart();
    const nameIsValid = isLength(name, { min: 1, max: 50 });
    const isValid =
      nameIsValid &&
      customerState.confirmEmailIsValid &&
      customerState.emailIsValid &&
      (!paymentConfig.captureTelephoneNumber ||
        customerState.telephoneIsValid) &&
      (!currentCinema.captureZipCode || customerState.zipCodeIsValid);

    handleCustomerChange({
      ...customerState,
      name,
      nameIsValid,
      isValid,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value.trim().toLowerCase();
    const emailIsValid = isBansheeSafeEmail(email);
    const confirmEmailIsValid =
      customerState.confirmEmail === email &&
      isBansheeSafeEmail(customerState.confirmEmail);
    const isValid =
      emailIsValid &&
      confirmEmailIsValid &&
      customerState.nameIsValid &&
      (!paymentConfig.captureTelephoneNumber ||
        customerState.telephoneIsValid) &&
      (!currentCinema.captureZipCode || customerState.zipCodeIsValid);

    handleCustomerChange({
      ...customerState,
      email,
      emailIsValid,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmEmail = e.currentTarget.value.trim().toLowerCase();
    const confirmEmailIsValid =
      isBansheeSafeEmail(confirmEmail) &&
      confirmEmail === customerState.email &&
      confirmEmail !== '';
    const isValid =
      confirmEmailIsValid &&
      customerState.nameIsValid &&
      customerState.emailIsValid &&
      (!paymentConfig.captureTelephoneNumber ||
        customerState.telephoneIsValid) &&
      (!currentCinema.captureZipCode || customerState.zipCodeIsValid);

    handleCustomerChange({
      ...customerState,
      confirmEmail,
      confirmEmailIsValid,
      isValid,
    });
  };

  const handleTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const telephone = e.currentTarget.value;
    const telephoneIsValid = isLength(telephone, { min: 1, max: 50 });
    const isValid =
      telephoneIsValid &&
      (!currentCinema.captureZipCode || customerState.zipCodeIsValid) &&
      customerState.confirmEmailIsValid &&
      customerState.emailIsValid &&
      customerState.nameIsValid;

    handleCustomerChange({
      ...customerState,
      telephone,
      telephoneIsValid,
      isValid,
    });
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const zipCode = e.currentTarget.value;
    const zipCodeIsValid = validateZipCode(zipCode, zipCodeMin);

    const isValid =
      zipCodeIsValid &&
      (!paymentConfig.captureTelephoneNumber ||
        customerState.telephoneIsValid) &&
      customerState.confirmEmailIsValid &&
      customerState.emailIsValid &&
      customerState.nameIsValid;

    handleCustomerChange({
      ...customerState,
      zipCode,
      zipCodeIsValid,
      isValid,
    });
  };

  if (!content) {
    return null;
  }

  return (
    <div className='customer-details' data-testid='customer-details'>
      <Form className='mt-1 text-start' noValidate>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='fullName'>
            {content.payment.fullNameLabel}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={content.payment.fullNamePlaceholder}
            onChange={handleNameChange}
            required
            maxLength={50}
            value={customerState.name}
            isInvalid={
              customerState.nameIsValidated && !customerState.nameIsValid
            }
            isValid={customerState.nameIsValidated && customerState.nameIsValid}
            onBlur={() =>
              setCustomerState({ ...customerState, nameIsValidated: true })
            }
            id='fullName'
            name='fullName'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.fullNameValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='email'>{content.payment.emailLabel}</Form.Label>
          <Form.Control
            type='email'
            placeholder={content.payment.emailPlaceholder}
            onChange={handleEmailChange}
            value={customerState.email}
            required
            isInvalid={
              customerState.emailIsValidated && !customerState.emailIsValid
            }
            isValid={
              customerState.emailIsValidated && customerState.emailIsValid
            }
            onBlur={() =>
              setCustomerState({ ...customerState, emailIsValidated: true })
            }
            id='email'
            name='email'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.emailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='mb-2'>
          <Form.Label htmlFor='confirmEmail'>
            {content.payment.confirmEmailLabel}
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder={content.payment.confirmEmailPlaceholder}
            onChange={handleConfirmEmailChange}
            value={customerState.confirmEmail}
            isInvalid={
              customerState.confirmEmailIsValidated &&
              !customerState.confirmEmailIsValid
            }
            isValid={
              customerState.confirmEmailIsValidated &&
              customerState.confirmEmailIsValid
            }
            onBlur={() =>
              setCustomerState({
                ...customerState,
                confirmEmailIsValidated: true,
              })
            }
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
              e.preventDefault()
            }
            id='confirmEmail'
            name='confirmEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.confirmEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        {paymentConfig.captureTelephoneNumber && (
          <Form.Group className='mb-2' data-testid='telephone'>
            <Form.Label htmlFor='telephone'>
              {content.payment.telephoneLabel}
            </Form.Label>
            <Form.Control
              type='text'
              placeholder={content.payment.telephonePlaceholder}
              onChange={handleTelephoneChange}
              required
              maxLength={50}
              value={customerState.telephone}
              isInvalid={
                customerState.telephoneIsValidated &&
                !customerState.telephoneIsValid
              }
              isValid={
                customerState.telephoneIsValidated &&
                customerState.telephoneIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  telephoneIsValidated: true,
                })
              }
              id='telephone'
              name='telephone'
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.telephoneValidationText}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        {currentCinema.captureZipCode && (
          <Form.Group className='mb-2' data-testid='zipCode'>
            <Form.Label htmlFor='zipCode'>
              {content.payment.zipCodeLabel}
            </Form.Label>
            <Form.Control
              type='text'
              placeholder={content.payment.zipCodePlaceholder}
              onChange={handleZipCodeChange}
              maxLength={8}
              value={customerState.zipCode}
              isInvalid={
                customerState.zipCodeIsValidated &&
                !customerState.zipCodeIsValid
              }
              isValid={
                customerState.zipCodeIsValidated && customerState.zipCodeIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  zipCodeIsValidated: true,
                })
              }
              id='zipCode'
              name='zipCode'
              required
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.zipCodeValidationText}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export default CustomerDetails;
