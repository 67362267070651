/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Row, Col, Button } from 'react-bootstrap'; // Assuming you're using react-bootstrap
import { useDispatch, useSelector } from 'react-redux';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedMemberCards,
  selectContent,
  selectCurrencyConfig,
} from '../../../store/Selectors';

interface AppliedMemberCardsProps {
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  tickets: TicketTypeModel[];
}

const AppliedMemberCards: React.FC<AppliedMemberCardsProps> = ({
  handleTicketButtonClick,
  tickets,
}) => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const currencyConfig = useSelector(selectCurrencyConfig);

  const handleRemoveMemberCard = (memberCardNo: string) => {
    const appliedMemberCard = appliedMemberCards?.find(
      (usedMemberCard) => usedMemberCard.memberCardNumber === memberCardNo
    );
    const ticketIdToRemove = appliedMemberCard?.usedTicket.id;
    if (ticketIdToRemove) {
      const memberTicket = tickets.find((t) => t.id === ticketIdToRemove);
      if (memberTicket?.validatedVouchers) {
        memberTicket.validatedVouchers = memberTicket.validatedVouchers.filter(
          (card) => card !== memberCardNo
        );
      }
      dispatch(actionCreators.removeMemberCard(memberCardNo));
      handleTicketButtonClick(ticketIdToRemove, 'remove');
    }
  };

  return (
    <div
      className='applied-loyalty-cards-container'
      data-testid='applied-loyalty-cards'
    >
      <div
        className='pt-4 pb-2 text-center applied-loyalty-cards-title h3'
        sx={{
          borderTop: 'bodySeparator',
        }}
      >
        {content.tickets.memberTicketsAdditionalTicketHeading}
      </div>
      {appliedMemberCards?.map((usedMemberCard: ValidatedMemberCard) => (
        <Row
          className='applied-loyalty-cards-item pt-2 pb-2 contained d-flex justify-content-between'
          key={usedMemberCard?.memberCardNumber}
          sx={{
            borderTop: 'bodySeparator',
          }}
        >
          <Col xs={6} md={8} className='text-start'>
            <span>
              {content.tickets.memberTicketsAddedMemberCardText}
              {usedMemberCard?.memberCardNumber}
            </span>
          </Col>
          <Col
            xs={3}
            md={2}
            className='float-start d-flex justify-content-center align-items-center small'
          >
            <Button
              onClick={() =>
                handleRemoveMemberCard(usedMemberCard?.memberCardNumber)
              }
              variant='link'
              size='sm'
            >
              <span className='tiny'>
                {content.payment.removeButtonText.toUpperCase()}
              </span>
            </Button>
          </Col>
          <Col
            xs={3}
            md={2}
            className='d-flex align-items-center justify-content-end strong'
          >
            <span>
              {displayPrice(usedMemberCard.usedTicket.price, currencyConfig)}
            </span>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default AppliedMemberCards;
