import React, { useState } from 'react';

import { Col, Form, Row } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidateMemberCardTicketModel,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedMemberCards,
  selectBookingData,
  selectContent,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  orderHasMaxTickets: boolean;
  handleAddTicket: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  ticket: TicketTypeModel;
}

export const MemberCardInput: React.FC<Props> = ({
  orderHasMaxTickets,
  handleAddTicket,
  ticket,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();

  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);
  const appliedMemberCards = useSelector(selectAppliedMemberCards);
  const dataToken = useSelector(selectToken);

  const [loyaltyCardInput, setLoyaltyCardInput] = useState('');
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);

  const isMemberCardNumberUsed = (memberCardNumberToCheck: string): boolean => {
    return (
      memberCardNumberToCheck === bookingData.loyaltyCardNumber ||
      appliedMemberCards?.some(
        (card: ValidatedMemberCard) =>
          card?.memberCardNumber === memberCardNumberToCheck
      )
    );
  };

  const validateMemberCard = async () => {
    if (!executeRecaptcha) return;

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'ValidateMemberCardTicket',
      executeRecaptcha
    );

    if (!ticket.isMemberTicket) return;

    const ticketCopy = { ...ticket };
    ticketCopy.quantity = 1;
    ticketCopy.voucherCode = loyaltyCardInput;

    const data: ValidateMemberCardTicketModel = {
      dataToken,
      recaptchaToken: recaptchaToken ?? null,
      ticketType: ticketCopy,
    };

    try {
      const response = await backend.post(
        'api/Tickets/ValidateMemberCardTicket',
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        await handleAddTicket(ticket.id, 'add');
        if (ticket.validatedVouchers) {
          ticket.validatedVouchers.push(loyaltyCardInput);
        } else {
          ticket.validatedVouchers = [loyaltyCardInput];
        }
        dispatch(
          actionCreators.addMemberCard({
            memberCardNumber: loyaltyCardInput,
            usedTicket: ticket,
          })
        );
        setLoyaltyCardInput('');
        setFeedback('');
      } else if (response.content.peachCode === PEACH_CODES.invalidMemberCard) {
        setFeedback(getContentForError(PEACH_CODES.invalidMemberCard, content));
      } else {
        setFeedback(getContentForError(PEACH_CODES.unknownError, content));
      }
    } catch (error) {
      setFeedback(getContentForError(PEACH_CODES.unknownError, content));
    } finally {
      setDisableApplyButton(false);
    }
  };

  const handleLoyaltyCardCheck = async () => {
    if (!loyaltyCardInput) return;
    setDisableApplyButton(true);

    if (!isMemberCardNumberUsed(loyaltyCardInput)) {
      validateMemberCard();
    } else {
      setDisableApplyButton(false);
      setFeedback(content.tickets.memberTicketsCardAlreadyAppliedText);
    }
  };

  return (
    <ContainedRow classNameRow='member-card-input-container mb-2 contained'>
      <p className='mt-2 mb-2 text-start'>
        {content.tickets.memberTicketsAdditionalCardText}
      </p>
      <Row>
        <Col>
          <Form.Control
            id='memberCardNumber'
            name='memberCardNumber'
            type='text'
            placeholder={content.tickets.memberTicketsCardPlaceHolder}
            onChange={(e) => {
              setLoyaltyCardInput(e.target.value);
              setFeedback('');
            }}
            value={loyaltyCardInput}
            disabled={disableApplyButton || orderHasMaxTickets}
            className='spaced-letters'
            data-testid='member-card-input'
          />
        </Col>
        <Col xs={4}>
          <ActionButton
            onClick={handleLoyaltyCardCheck}
            disabled={
              disableApplyButton || orderHasMaxTickets || !loyaltyCardInput
            }
            variant='secondary'
            mb={0}
            mt={0}
          >
            {disableApplyButton ? (
              <ActionButtonSpinner />
            ) : (
              content.checkButtonText
            )}
          </ActionButton>
        </Col>
      </Row>
      {!!feedback && (
        <div className='multiple-loyalty-cards-selector warning-container mt-3 p-3'>
          <p>{feedback}</p>
        </div>
      )}
    </ContainedRow>
  );
};
