import React from 'react';

import { useSelector } from 'react-redux';

import TokenReplacer from '../../../../../services/TokenReplacer';
import { selectContent } from '../../../../../store/Selectors';
import Heading from '../../../heading/Heading';
import ContainedRow from '../../../layout/ContainedRow';
import RichText from '../../../richtext/RichText';

const CardPaymentPromotionPaymentReminder: React.FC = () => {
  const content = useSelector(selectContent);

  return (
    <ContainedRow>
      <Heading className='mt-3 text-center' size={2}>
        {content.payment.cardPaymentPromoTicketsPaymentPageReminderTitle}
      </Heading>
      <RichText
        className='mb-1'
        text={TokenReplacer(
          content.payment.cardPaymentPromoTicketsPaymentPageReminderRichText
        )}
      />
    </ContainedRow>
  );
};

export default CardPaymentPromotionPaymentReminder;
