import React from 'react';

import { useSelector } from 'react-redux';

import RecipientDetails from './RecipientDetailsEGiftCard';

import { ContentGiftCards } from '../../../../@types/contentTypes';
import {
  selectContent,
  selectGiftCardRecipient,
} from '../../../../store/Selectors';
import CustomerDetails from '../../customerdetails/CustomerDetails';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import TermsAndConditions from '../../termsandconditions/TermsAndConditions';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const content = useSelector(selectContent);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const giftCardContent: ContentGiftCards = content.giftCards;

  if (!content) {
    return null;
  }

  return (
    <>
      <div className='delivery-details contained'>
        <Heading size={2} className='text-center mt-5'>
          2. {giftCardContent.recipientDetailsHeading}
        </Heading>
        <RichText
          styles={{ mb: 5 }}
          text={giftCardContent.recipientDetailsRichText}
        />
        <RecipientDetails
          isPageValidated={isPageValidated}
          billingAsRecipient={giftCardRecipient.useSameDetailsForBilling}
        />
        <Heading size={2} className='mt-5 text-center'>
          3. {giftCardContent.customerDetailsHeading}
        </Heading>
        <RichText text={giftCardContent.customerDetailsRichText} />
        <p className='fw-bold mt-2'>{giftCardContent.useBillingEmailText}</p>
        <CustomerDetails isPageValidated={isPageValidated} />
      </div>

      <ContainedRow>
        <TermsAndConditions isPageValidated={isPageValidated} />
      </ContainedRow>
    </>
  );
};

export default DeliveryDetails;
