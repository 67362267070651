/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import FandBItemImage from './FandBItemImage';
import FandBItemModalGoBack from './FandBItemModalGoBack';
import FandBItemModalModifiers from './FandBItemModalModifiers';
import FandBItemModalQtyButtons from './FandBItemModalQtyButtons';

import { ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectFandBItemModalData,
  selectImageProcessorContainer,
  selectImageProcessorUrl,
} from '../../../store/Selectors';
import RichText from '../richtext/RichText';

interface Props {
  showToast: (message: string) => void;
}

const FandBItemModal: React.FC<Props> = ({ showToast }) => {
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const fandbItemModalData = useSelector(selectFandBItemModalData);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);

  const [refresh, setRefresh] = useState<number>(0);

  const concessionModalRef = useRef(null);

  if (!fandbItemModalData?.concession) return null;

  const concession = fandbItemModalData.concession;
  const showModal = fandbItemModalData.showModal;

  const imagePath = concession.image
    ? concession.image.replace('https://', '')
    : null;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const hideTax = config.currentCinema.hideTax;

  const imageUrl: string = imagePath
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imagePath}?height=200`
    : '/images/concession-fallback.png';

  return (
    <Modal
      show={showModal}
      onHide={() => dispatch(actionCreators.removeFandBItemModalData())}
      centered
      className='layout-modal small-buttons'
      backdrop='static'
      keyboard={false}
      ref={concessionModalRef}
      sx={{
        '.modal-content': {
          border: 'none',
          borderRadius: '6px',
          overflow: 'scroll',

          '@media screen and (min-width: 576px)': {
            maxHeight: '500px',
          },

          '.modifier-groups': {
            '.status': {
              alignItems: 'center',
              borderRadius: '15px',
              display: 'flex',
              fontSize: 'small',
              padding: '2px 12px',
              textTransform: 'uppercase',
            },

            '.quantity-options': {
              '.multi-option-container': {
                backgroundColor: 'websiteBackground',
              },
            },

            '.multi-option-container': {
              '&.bordered': {
                padding: '0',
                '&.has-selection:hover': {
                  borderColor: 'primary',
                },
              },

              '.multi-option': {
                '.heading-btn': {
                  '&.open': {
                    borderBottom: 'boxSeparatorDashed',
                  },

                  '.h3': {
                    color: 'mostReadableOnWebsiteBackground',
                  },

                  '&:hover': {
                    '.h3': {
                      color: 'primary',
                    },
                  },
                },

                '.close-icon': {
                  '.a': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                },
              },
            },

            '.modifier-item': {
              borderTop: 'boxSeparator',

              '&:last-child': {
                borderBottom: 'boxSeparator',
                marginBottom: 7,
              },

              button: {
                color: 'mostReadableOnWebsiteBackground',

                svg: {
                  height: '35px',
                  width: '35px',

                  '.a': {
                    fill: 'boxBackground',
                  },
                  '.b': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                  '.c': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                },

                '.unsel': {
                  '.a': {
                    fill: 'boxBackground',
                  },
                  '.b': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                },

                '.sel': {
                  '.a': {
                    fill: 'boxBackground',
                  },
                  '.b': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                  '.c': {
                    fill: 'mostReadableOnWebsiteBackground',
                  },
                },
              },

              '&.selected': {
                button: {
                  color: 'mostReadableOnWebsiteBackground',
                },
              },

              '&-selected': {
                '& button svg': {
                  '.a': {
                    fill: 'boxBackground',
                  },
                  '.b': {
                    fill: 'seatSelected',
                  },
                  '.c': {
                    fill: 'seatSelected',
                  },
                },
              },
            },
          },
        },
      }}
    >
      <Box>
        <Box
          py={3}
          sx={{
            backgroundColor: 'websiteBackground',
            position: 'sticky',
            textAlign: 'center',
            top: 0,
            zIndex: '1100',
          }}
        >
          <Box sx={{ fontWeight: 'bold' }}>
            <FandBItemModalGoBack
              onClick={() =>
                dispatch(actionCreators.removeFandBItemModalData())
              }
            />
            {concession.description}
          </Box>
          <Box sx={{ fontSize: 'small' }}>
            {itemPricing.costIncTax > 0
              ? displayPrice(
                  hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax,
                  currencyConfig
                )
              : content.kiosk.selectOptionsButtonText}
          </Box>
        </Box>
        <Flex
          sx={{
            justifyContent: !concession.extendedDescription && 'center',
            pb: 4,
          }}
        >
          <FandBItemImage imageUrl={imageUrl} />

          {concession.extendedDescription && (
            <Flex>
              <RichText
                styles={{ ml: 3, fontSize: 'small' }}
                text={concession.extendedDescription}
              />
            </Flex>
          )}
        </Flex>
        <Box>
          <FandBItemModalModifiers
            concession={concession}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </Box>
        <Box
          py={3}
          sx={{
            backgroundColor: 'websiteBackground',
            position: 'sticky',
            bottom: 0,
            zIndex: '1100',
          }}
        >
          <FandBItemModalQtyButtons
            concession={concession}
            showToast={showToast}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default FandBItemModal;
