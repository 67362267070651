import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { RefundServiceCharge } from '../../../@types/enums';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectRefundData,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import LoginSection from '../login/LoginSection';
import RichText from '../richtext/RichText';

interface Props {
  handleLogInClick: () => void;
  handleSubmitRefund: (
    isLoyaltyRefund: boolean,
    refundIncludesServiceCharge: boolean
  ) => void;
}

const RefundRequest: React.FC<Props> = ({
  handleLogInClick,
  handleSubmitRefund,
}) => {
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const refundData = useSelector(selectRefundData);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  if (
    refundData?.isRefundAllowedForMemberLevelId &&
    !bookingData.isUserValidated &&
    config.currentCinema.refundServiceCharge === RefundServiceCharge.NO
  ) {
    return (
      <Row data-testid='refund-request-is-allowed-for-member-level'>
        <Col className='contained' xs={12}>
          <RichText text={content.refund.loyaltyLoginRefundRequestMessage} />
          <LoginSection />
        </Col>
      </Row>
    );
  }

  return (
    <>
      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        !bookingData.isUserValidated && (
          <Row data-testid='refund-request-loyalty-service-charge'>
            <Col className='contained' xs={12}>
              <RichText
                text={content.refund.loyaltyLoginRefundRequestMessage}
              />
              <ActionButton
                onClick={handleLogInClick}
                showIcon
                contained
                mb={0}
                variant='primary'
              >
                {content.refund.logInButtonText}
              </ActionButton>
            </Col>
            <Col className='mt-4'>
              <RichText text={content.refund.continueAsGuestMessage} />

              <Row className='g-0 mt-3'>
                <Col xs='auto' className='d-flex align-items-center me-3'>
                  <CheckBoxButton
                    checked={checked}
                    onClick={handleCheckboxChange}
                  />
                </Col>
                <Col>
                  <RichText
                    text={content.refund.refundAgreeTermsText}
                    className='text-start'
                  />
                </Col>
              </Row>
              <ActionButton
                onClick={() => handleSubmitRefund(false, false)}
                showIcon
                contained
                disabled={!checked}
                mb={0}
                variant='primary'
              >
                {content.refund.continueAsGuestButtonText}
              </ActionButton>
            </Col>
          </Row>
        )}

      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        bookingData.isUserValidated && (
          <Row data-testid='refund-signed-in'>
            <Col className='contained' xs={12}>
              <RichText
                text={content.refund.loyaltyRefundRequestConfirmation}
              />
              <ActionButton
                onClick={() => handleSubmitRefund(true, true)}
                showIcon
                contained
                variant='primary'
              >
                {content.refund.confirmButtonText}
              </ActionButton>
            </Col>
          </Row>
        )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.YES && (
        <Row data-testid='refund-request-yes-service-charge'>
          <Col className='contained' xs={12}>
            <RichText text={content.refund.genericRefundRequestMessage} />
            <ActionButton
              onClick={() => handleSubmitRefund(false, true)}
              showIcon
              contained
              mb={0}
              variant='primary'
            >
              {content.refund.confirmButtonText}
            </ActionButton>
          </Col>
        </Row>
      )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.NO && (
        <Row data-testid='refund-request-no-service-charge'>
          <Col className='contained' xs={12}>
            <RichText
              text={content.refund.nonLoyaltyRefundRequestConfirmation}
            />

            <Row className='g-0 mt-3'>
              <Col xs='auto' className='d-flex align-items-center me-3'>
                <CheckBoxButton
                  checked={checked}
                  onClick={handleCheckboxChange}
                />
              </Col>
              <Col>
                <RichText
                  text={content.refund.refundAgreeTermsText}
                  className='text-start'
                />
              </Col>
            </Row>
            <ActionButton
              onClick={() => handleSubmitRefund(false, false)}
              showIcon
              contained
              disabled={!checked}
              mb={0}
              variant='primary'
            >
              {content.refund.confirmButtonText}
            </ActionButton>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RefundRequest;
