import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { Concession, GlobalState } from '../../../../../@types/modelTypes';
import { getMatchingConcessionDealDetails } from '../../../../../services/Helpers';
import {
  selectContent,
  selectDeals,
  selectDisplayPrice,
} from '../../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../../svgs/deal.svg';
import { ReactComponent as RadioSelectedSvg } from '../../../../../svgs/radioSelected.svg';

interface FixedCostConcession extends Concession {
  handleConcessionSelect: (c: Concession, isVariable: boolean) => void;
  selectedGiftCard: Concession | undefined;
  disabled: boolean;
}

const FixedCostGiftCardButton: React.FC<FixedCostConcession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  orderDelivery,
  handleConcessionSelect,
  selectedGiftCard,
  disabled,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    orderDelivery,
    modifiers: [],
    modifierGroups,
    hidden: false,
    variablePriceInCents: 0,
    isDeliveryItem: false,
    isSoldOut: false,
  };

  const content = useSelector(selectContent);
  const deals = useSelector(selectDeals);
  const isSelected =
    selectedGiftCard &&
    selectedGiftCard.id === concession.id &&
    selectedGiftCard.quantity > 0;
  const matchedConcessionDeal = getMatchingConcessionDealDetails(id, deals);
  const concessionCostToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, concession.cost)
  );
  const costAfterDealToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(
      state,
      concession.cost -
        (matchedConcessionDeal ? matchedConcessionDeal.amount : 0)
    )
  );
  const handleOnClick = () => {
    if (!disabled) {
      handleConcessionSelect(concession, false);
    }
  };
  return (
    <div
      onClick={handleOnClick}
      onKeyDown={handleOnClick}
      className={classnames(
        'giftcard-container fixed-price',
        disabled && 'disabled'
      )}
    >
      {matchedConcessionDeal && <DealSvg className={classnames('deal-icon')} />}
      <button
        className={classnames(
          'giftcard-button d-flex align-items-center justify-content-center',
          isSelected && 'selected'
        )}
        disabled={disabled}
        title={concession.description}
        type='button'
      >
        <Flex className='align-items-center' sx={{ alignItems: 'center' }}>
          <Flex
            className='fw-bold d-flex  item-cost'
            sx={{ alignItems: 'center' }}
          >
            {isSelected && <RadioSelectedSvg className='check' />}
            {concessionCostToDisplay}
          </Flex>
          {matchedConcessionDeal && matchedConcessionDeal.amount > 0 && (
            <div className='tiny deal-text text-uppercase'>{`(${content.giftCards.saveText} ${costAfterDealToDisplay})`}</div>
          )}
        </Flex>
      </button>
    </div>
  );
};

export default FixedCostGiftCardButton;
