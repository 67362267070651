import React, { useState, useCallback, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import landingContent from './LandingPageContent.json';

import { Cinema } from '../../../@types/configTypes';
import { FoodAndBeverageJourneyDeliveryType } from '../../../@types/enums';
import { StartJourneyModel } from '../../../@types/modelTypes';
import {
  forceNewJourneyKey,
  JOURNEY_TYPES,
  PEACH_CODES,
} from '../../../constants';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectConfig, selectContent } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import BookingInfo from '../../common/kiosk/landingpage/BookingInfo';
import BookingRefCapture from '../../common/kiosk/landingpage/BookingRefCapture';
import CinemaSelector from '../../common/kiosk/landingpage/CinemaSelector';
import ContinueButtons from '../../common/kiosk/landingpage/ContinueButtons';
import Headings from '../../common/kiosk/landingpage/Headings';
import ContainedRow from '../../common/layout/ContainedRow';

const ConcessionsLanding: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [searchParams] = useSearchParams();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const [bookingRef, setBookingRef] = useState(
    searchParams.get('bookingRef') ?? ''
  );
  const [startJourneyModel, setStartJourneyModel] = useState<
    StartJourneyModel | undefined
  >(undefined);
  const [pathname, setPathname] = useState('');
  const [isBookingRefInvalid, setIsBookingRefInvalid] = useState(false);
  const [cinemaId, setCinemaId] = useState(
    searchParams.get('cinemaId') ?? '-1'
  );

  const journeyType = JOURNEY_TYPES.CONCESSIONS_ONLY;
  const circuitId = searchParams.get('circuitId');
  const cinema =
    config && cinemaId && config.cinemas
      ? config.cinemas.find((x) => x.cinemaId === parseInt(cinemaId))
      : undefined;

  const cinemasWithKiosk: Cinema[] | undefined = config?.cinemas?.filter(
    (x: Cinema) =>
      x.foodAndBeverageConcessionsOnlyJourney ==
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly ||
      x.foodAndBeverageConcessionsOnlyJourney ==
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskAndOrderToSeat
  );

  const hasCinemasWithKiosk =
    cinemasWithKiosk !== undefined && cinemasWithKiosk.length > 0;

  const isKioskAvailableAtCinema: boolean = cinema
    ? ![
        FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly,
        FoodAndBeverageJourneyDeliveryType.Disabled,
      ].includes(cinema.foodAndBeverageConcessionsOnlyJourney)
    : false;

  const shouldShowCinemaSelector =
    !startJourneyModel &&
    config &&
    config.allowCinemaSelection &&
    hasCinemasWithKiosk;

  const shouldShowContinueWithKioskOnly =
    isKioskAvailableAtCinema && !startJourneyModel && !bookingRef;

  const startKioskOnlyJourney = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const selectedLanguageCulture =
      cookies.preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    const { requestData } = cookies;

    if (requestData) {
      dispatch(actionCreators.setRequestData(requestData));
    }

    const data = {
      selectedLanguageCulture,
      circuitId,
      requestData,
    };
    const path = `api/StartConcessions/${cinemaId}${
      bookingRef ? `?bookingRef=${bookingRef}` : ''
    }`;
    const response = await backend.post(path, data);
    let bookingData, config, content, customer, result, reservedSeats;
    if (
      response.ok &&
      (response.content.peachCode == PEACH_CODES.ok ||
        response.content.peachCode == PEACH_CODES.noError)
    ) {
      result = response.content;
      bookingData = result.bookingData;
      reservedSeats = result.reservedSeats;
      config = result.circuit.config;
      content = result.circuit.content;
      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );

      delete config.cinemas;
      delete content.cinemas;

      const isUserValidated = bookingData?.isUserValidated;
      const allowSignIn =
        config.currentCinema?.allowSignIn || config.signIn.allowSignIn;
      const step = allowSignIn && !isUserValidated ? 0 : 1;
      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        currency: 'USD',
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: { ticketTypeModels: [] },
        donation: 0,
        bookingFee: 0,
        seatsModel: result.selectSeatsModel,
        error: { show: false, message: '' },
        selectedSeats: reservedSeats,
        giftCard: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: isUserValidated,
        journeyType: journeyType,
        customer: customer,
        source: source,
        device: device,
        step: step,
        kioskSubStep: 'fab',
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        selectedDeliveryWindow: null,
        selectedLanguageCulture: content.culture,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
      };
      const journeyTypeConfig = journeyTypeConfigs[journeyType];
      dispatch(actionCreators.initializeSession(session));
      const route = getRouteFromStep(journeyTypeConfig, step);

      window.sessionStorage.removeItem(forceNewJourneyKey);

      navigate(`/${route}`);
    } else {
      result = response.content;
      config = result.circuit.config;
      content = result.circuit.content;

      delete config.cinemas;
      delete content.cinemas;

      const session = {
        loading: true,
        config: config,
        content: content,
        error: {
          show: true,
          message: getContentForError(response.content.peachCode, content),
        },
        step: -1,
      };

      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      dispatch(actionCreators.initializeSession(session));

      window.sessionStorage.removeItem(forceNewJourneyKey);
    }
    dispatch(actionCreators.setLoading(false));
  }, [
    bookingRef,
    cinemaId,
    circuitId,
    cookies,
    dispatch,
    journeyType,
    navigate,
    searchParams,
  ]);

  const checkBookingRef = useCallback(async () => {
    if (bookingRef) {
      dispatch(actionCreators.setLoading(true));
      const url = `api/startConcessions/foodAndBeverage?bookingRef=${bookingRef}`;
      const body = { circuitId: config.circuitId };
      const bookingRefResponse = await backend.post(url, body);
      if (
        bookingRefResponse.ok &&
        (bookingRefResponse.content.peachCode === PEACH_CODES.noError ||
          bookingRefResponse.content.peachCode === PEACH_CODES.ok)
      ) {
        const startJourneyModel: StartJourneyModel = bookingRefResponse.content;
        setCinemaId(String(startJourneyModel.bookingData.cinemaId));
        setStartJourneyModel(startJourneyModel);
        setPathname(
          `/startconcessions/${startJourneyModel.bookingData.cinemaId}?circuitId=${config.circuitId}&bookingRef=${bookingRef}`
        );
        setIsBookingRefInvalid(false);
      } else {
        dispatch(
          actionCreators.setError(
            getContentForError(bookingRefResponse.content.peachCode, content),
            bookingRefResponse.content.peachCode
          )
        );
        if (
          bookingRefResponse.content.peachCode === PEACH_CODES.orderNotFound
        ) {
          setIsBookingRefInvalid(true);
        }
      }

      dispatch(actionCreators.setLoading(false));
    }
  }, [dispatch, bookingRef, config, content]);

  useEffect(() => {
    const getConfigAndContent = async () => {
      dispatch(actionCreators.clearSession());
      dispatch(actionCreators.setLoading(true));
      const { requestData } = cookies;
      const response = await backend.post('api/Config', {
        selectedLanguageCulture:
          cookies.preferredLanguageCulture ?? searchParams.get('culture'),
        circuitId: circuitId ? parseInt(circuitId) : 0,
        requestData,
      });
      if (response.ok) {
        const result = response.content;
        const config = result.config;
        const content = result.content;
        dispatch(actionCreators.setCircuitConfig(config));
        dispatch(actionCreators.setCircuitContent(content));
        if (requestData) {
          dispatch(actionCreators.setRequestData(requestData));
        }
        if (config.sentryDsnKey) {
          loadSentry(config.sentryDsnKey);
        }
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!config) {
      getConfigAndContent();
    }
  }, [circuitId, cookies, dispatch, config, searchParams]);

  useEffect(() => {
    // Can auto-start Kiosk-Only journey
    if (cinema && !bookingRef) {
      if (
        cinema.foodAndBeverageConcessionsOnlyJourney ==
          FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly &&
        !cinema.foodAndBeverageJourneyRequiresBookingRef
      ) {
        startKioskOnlyJourney();
      }
    }
  }, [bookingRef, cinema, startKioskOnlyJourney]);

  const handleBookingRefChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBookingRef(e.currentTarget.value);
    setIsBookingRefInvalid(false);
  };

  const handleCinemaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCinemaId(e.currentTarget.value);
  };

  const resetBookingRef = () => {
    setBookingRef('');
    setStartJourneyModel(undefined);
    setIsBookingRefInvalid(false);
  };

  if (!config || !content) return null;

  return (
    <div className='concessions-landing'>
      <Headings />
      {startJourneyModel && (
        <>
          <BookingInfo
            reservedSeats={startJourneyModel.reservedSeats}
            useStillImageInCartSummary={config.useStillImageInCartSummary}
            bookingRef={bookingRef}
          />
          {bookingRef && (
            <ContinueButtons
              pathname={pathname}
              resetBookingRef={resetBookingRef}
              bookingRef={bookingRef}
            />
          )}
        </>
      )}

      {!startJourneyModel && (
        <BookingRefCapture
          bookingRef={bookingRef}
          isBookingRefInvalid={isBookingRefInvalid}
          handleBookingRefChange={handleBookingRefChange}
          checkBookingRef={checkBookingRef}
        />
      )}

      {shouldShowCinemaSelector && (
        <CinemaSelector
          isKioskAvailable={isKioskAvailableAtCinema}
          allowCinemaSelection={
            config?.allowCinemaSelection && hasCinemasWithKiosk
          }
          cinemas={config?.cinemas}
          handleCinemaChange={handleCinemaChange}
          cinemaId={cinemaId}
        />
      )}
      {shouldShowContinueWithKioskOnly && (
        <ContainedRow classNameCol='text-start'>
          <ActionButton
            onClick={startKioskOnlyJourney}
            disabled={false}
            stickyMobileDesktop={false}
            showIcon
            contained
            mx={5}
            variant='primary'
          >
            {landingContent.kioskOnlyContinueButtonText}
          </ActionButton>
        </ContainedRow>
      )}
    </div>
  );
};

export default ConcessionsLanding;
