import React from 'react';

import { Box } from 'theme-ui';

import Heading from '../heading/Heading';

interface Props {
  warningTitle?: string;
  warningMessage?: string;
}

const WarningMessage: React.FC<Props> = ({ warningTitle, warningMessage }) => {
  if (!warningMessage) {
    return null;
  }

  return (
    <Box
      className='warning-container'
      data-testid='warning-message'
      sx={{ mb: 5, p: warningTitle ? 5 : 4 }}
    >
      <Heading size={3} className='text-center mt-0 mb-2'>
        {warningTitle}
      </Heading>
      {warningMessage && <p className='m-0'>{warningMessage}</p>}
    </Box>
  );
};

export default WarningMessage;
