import React, { useState } from 'react';

import { Col, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import TicketSelectorContainer from './TicketSelectorContainer';

import { handleRegisterClick } from '../../../services/LoyaltyHelpers';
import {
  selectBookingData,
  selectContent,
  selectRegisterUrl,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';
import ContainedRow from '../layout/ContainedRow';
import LoginSection from '../login/LoginSection';

interface Props {
  orderHasMaxTickets: boolean;
}

const LoyaltyTicketSelector: React.FC<Props> = ({ orderHasMaxTickets }) => {
  const content = useSelector(selectContent);
  const bookingData = useSelector(selectBookingData);
  const ticketTypes = useSelector(selectTicketTypes);

  const registerUrl = useSelector(selectRegisterUrl);

  const [showLoyaltyCardForm, setShowLoyaltyCardForm] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showLoginSection, setShowLoginSection] = useState(false);

  const hasMemberTickets = ticketTypes?.ticketTypeModels.find(
    (t) => t.isMemberTicket
  );

  if (!ticketTypes || !hasMemberTickets) return null;

  const onLoginClick = () => {
    setShowLoginSection(true);
  };

  const onGoBack = () => {
    setShowLoginSection(false);
  };

  return (
    <BorderedCollapse
      closeButtonText={content.payment.closeButtonText}
      heading={content.tickets.memberTicketsHeading}
      setShow={setShowLoyaltyCardForm}
      show={showLoyaltyCardForm}
    >
      {(!showLoginSection || bookingData.isUserValidated) && (
        <div className='loyalty-ticket-selector-container'>
          <div
            className='loyalty-ticket-selector'
            data-testid='loyalty-ticket-selector'
          >
            <p>{content.tickets.memberTicketsInfoText}</p>
            <Form>
              <Form.Label />
              <TicketSelectorContainer
                ticketTypeGroup='members-only'
                orderHasMaxTickets={orderHasMaxTickets}
                setShowRegister={setShowRegister}
              />

              {showRegister && !bookingData.isUserValidated && (
                <ContainedRow
                  classNameCol='register-section d-flex flex-column'
                  data-testid='register-section'
                >
                  <span className='mt-2'>
                    {' '}
                    {content.tickets.memberTicketsLoginOrRegisterText}
                  </span>
                  <div className='register-section-buttons d-flex justify-content-between mt-2'>
                    <Col className='me-2'>
                      <ActionButton
                        onClick={onLoginClick}
                        variant='secondary'
                        mb={0}
                        mt={0}
                      >
                        {content.tickets.memberTicketsLoginButtonText}
                      </ActionButton>
                    </Col>
                    <Col>
                      <ActionButton
                        onClick={() => handleRegisterClick(registerUrl)}
                        variant='secondary'
                        mb={0}
                        mt={0}
                      >
                        {content.tickets.memberTicketsRegisterButtonText}
                      </ActionButton>
                    </Col>
                  </div>
                </ContainedRow>
              )}
            </Form>
          </div>
        </div>
      )}
      {showLoginSection && !bookingData.isUserValidated && (
        <div className='mt-2'>
          <div className='mx-3'>
            {content.tickets.memberTicketsReselectReminderText}
          </div>
          <LoginSection />
          <ActionButton
            mb={4}
            mt={0}
            mx={5}
            classNameContainer='btn-large'
            onClick={onGoBack}
            showIcon
            contained
            iconReversed
            variant='secondary'
          >
            {content.goBackText}
          </ActionButton>
        </div>
      )}
    </BorderedCollapse>
  );
};

export default LoyaltyTicketSelector;
