import React from 'react';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { GuestMarketing } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectGuestMarketing,
} from '../../../../store/Selectors';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';
interface Props {
  onEdit?: () => void;
}

const ThirdPartyCheckbox: React.FC<Props> = ({ onEdit }) => {
  const dispatch = useDispatch();

  const guestMarketing = useSelector(selectGuestMarketing);
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  if (!configPayment || !contentPayment) return null;

  return (
    <Flex className='checkbox-with-text' sx={{ mt: 4, ml: 2 }}>
      {configPayment.showThirdPartyCheckbox && (
        <Flex sx={{ alignItems: 'center' }}>
          <CheckBoxButton
            checked={!!guestMarketing?.contactByThirdParty}
            onClick={() => {
              !!onEdit && onEdit();
              dispatch(
                actionCreators.setGuestMarketing({
                  ...guestMarketing,
                  contactByThirdParty: !guestMarketing?.contactByThirdParty,
                } as GuestMarketing)
              );
            }}
          />
        </Flex>
      )}
      <Flex sx={{ alignItems: 'center' }}>
        <p
          className={classnames(
            'm-0 text-start tiny',
            guestMarketing?.contactByThirdParty && 'strong'
          )}
          data-testid='third-party'
        >
          {contentPayment.guestMarketingThirdPartyCheckboxText}
        </p>
      </Flex>
    </Flex>
  );
};

export default ThirdPartyCheckbox;
