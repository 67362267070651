/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react';

import { Overlay, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';
import { isFloat } from 'validator';

import { Concession, GlobalState } from '../../../@types/modelTypes';
import {
  getToolTipText,
  getValueLimitedToTwoDecimalPlaces,
} from '../../../services/GiftCardHelpers';
import { selectCurrencySymbol } from '../../../store/Selectors';

interface Props {
  concession: Concession;
  currentValue: string;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
}

const ConcessionVariableInput: React.FC<Props> = ({
  concession,
  currentValue,
  setCurrentValue,
}) => {
  const contentPhysicalGiftCards = useSelector(
    (state: GlobalState) => state.content.physicalGiftCards
  );
  const [showVariableCostTooltip, setShowVariableCostTooltip] = useState(false);
  const currencySymbol = useSelector(selectCurrencySymbol);

  const variableCostRef = useRef(null);

  useEffect(() => {
    if (
      currentValue === '' ||
      (concession.minVariableCost &&
        concession.minVariableCost <= Number(currentValue) * 100)
    ) {
      setShowVariableCostTooltip(false);
    } else {
      setShowVariableCostTooltip(true);
    }
  }, [concession.minVariableCost, currentValue]);

  const onChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValueLimitedToTwoDecimalPlaces(e.target.value);
    const newValueInCents: number = Number(value) * 100;
    if (value === '' || value === '.' || isFloat(value)) {
      if (
        concession.maxVariableCost !== null &&
        concession.maxVariableCost < newValueInCents
      ) {
        setShowVariableCostTooltip(true);
        setTimeout(() => {
          setShowVariableCostTooltip(false);
        }, 4000);
        return;
      }
      setCurrentValue(value);
    }
  };

  return (
    <>
      <Overlay
        placement='top'
        show={showVariableCostTooltip}
        target={variableCostRef}
      >
        <Tooltip
          data-testid='variable-giftcard-tooltip'
          id='physicalGiftCardVariableLimit'
        >
          {getToolTipText(
            contentPhysicalGiftCards.customAmountTooltipText,
            concession.minVariableCost,
            concession.maxVariableCost,
            currencySymbol
          )}
        </Tooltip>
      </Overlay>
      <Flex
        className='variable-cost-container'
        ref={variableCostRef}
        id={`${'variable_' + concession.id}`}
        sx={{
          backgroundColor: 'formInputBackground',
          borderColor: 'mostReadableOnWebsiteBackground',
          color: 'formInputColor',
          flexShrink: 0,
          alignItems: 'center',
          p: 2,
        }}
      >
        <span sx={{ mr: '2px' }}>{currencySymbol}</span>
        <input
          className='variable-cost-input '
          data-testid='variable-amount-input'
          placeholder={contentPhysicalGiftCards.customAmountPlaceholder}
          value={currentValue}
          onChange={onChangeCost}
          type='tel'
          min='1'
          autoFocus
        />
      </Flex>
    </>
  );
};

export default ConcessionVariableInput;
