import React, { useEffect, useState } from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import TokenReplacer from '../../../services/TokenReplacer';
import {
  selectBookingData,
  selectContent,
  selectSelectedSeasonPassItem,
  selectSelectedSeasonPassSessions,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import Heading from '../heading/Heading';

const loginStatusHeight = 42;
const cartSummaryBarHeight = 44;

interface Props {
  seasonPassBarHeight: number;
}
const SeasonPassShowtimesTopBar: React.FC<Props> = ({
  seasonPassBarHeight,
}) => {
  const content = useSelector(selectContent);
  const bookingData = useSelector(selectBookingData);
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );

  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);
  const { minRequiredMovies } = selectedSeasonPassItem;
  const selectedNumberOfSessions = selectedSeasonPassSessions?.length;
  const [shouldAddMargin, setShouldAddMargin] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const topbar = document.querySelector('.seasonpass-topbar');

      if (topbar) {
        if (bookingData?.isUserValidated) {
          if (scrollTop > loginStatusHeight) {
            setShouldAddMargin(false);
          } else {
            setShouldAddMargin(true);
          }
        } else {
          setShouldAddMargin(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [bookingData]);

  const originalText = TokenReplacer(
    content.seasonPass.selectSessionsTopBarText
  );

  const regex = new RegExp(
    `(${selectedNumberOfSessions}.*?${minRequiredMovies})`
  );

  const parts = originalText.split(regex);

  const showCheckmark = selectedNumberOfSessions >= minRequiredMovies;

  const topOffset = `${
    cartSummaryBarHeight + (shouldAddMargin ? loginStatusHeight : 0)
  }px`;
  return (
    <Flex
      className={classnames('seasonpass-topbar offcanvas-title')}
      data-testid='header-login'
      sx={{
        bg: 'websiteBackground',
        boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
        position: 'fixed',
        top: [
          topOffset,
          topOffset,
          topOffset,
          shouldAddMargin ? loginStatusHeight : 0,
        ],
        zIndex: 1010,
        width: ['100%', '100%', '100%', '66.667%'],
        height: `${seasonPassBarHeight}px`,
        transition: 'top 0.1s',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Heading
        size={2}
        className={classnames(
          'select-heading d-flex align-items-center justify-content-center',
          showCheckmark && 'all-selected'
        )}
      >
        {showCheckmark && <RadioSelectedSvg className='all-selected-check' />}
        <span>
          {parts.map((part, index) =>
            regex.test(part) ? <strong key={index}>{part}</strong> : part
          )}
        </span>
      </Heading>
    </Flex>
  );
};

export default SeasonPassShowtimesTopBar;
