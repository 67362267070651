/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { GlobalState } from '../../../@types/modelTypes';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectDisplayPrice,
  selectGrandTotalAfterDiscountsInCents,
} from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';
import Countdown from '../countdown/Countdown';

const TotalWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const { isLargeScreenWidth } = useScreenWidth();

  const content = useSelector(selectContent);
  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, grandTotalToUse)
  );

  return (
    <Flex
      className={classNames('total-wrapper', isLargeScreenWidth && 'mt-4')}
      data-testid='totalwrapper'
      sx={{
        backgroundColor: 'totalBarBackground',
        color: 'mostReadableOnTotalBarBackground',
        alignContent: 'center',
        mx: -3,
        justifyContent: isLargeScreenWidth ? 'center' : 'flex-start',
        '.total-text': {
          fontFamily: 'heading',
        },

        '.countdown-wrapper': {
          '.countdown-container': {
            backgroundColor: 'totalBarBackgroundLighter',
            border: 'mostReadableOnTotalBarBackgroundBorder',
          },
        },
      }}
    >
      {isLargeScreenWidth ? (
        <Box>
          <span className='total-text px-2'>
            {`${content.payment.totalToPayText} : ${priceToDisplay}`}
          </span>
        </Box>
      ) : (
        <>
          <Box
            className='cart-button'
            sx={{
              svg: {
                fill: 'mostReadableOnTotalBarBackground',
              },
            }}
          >
            <Button
              className='d-flex'
              onClick={() => dispatch(actionCreators.setCartSummaryOpen(true))}
              variant='link'
            >
              {grandTotalToUse ? <CartFull /> : <CartEmpty />}
            </Button>
          </Box>
          <Box className='text-start total-text p-0'>{priceToDisplay}</Box>
        </>
      )}
      <Box className='countdown-wrapper'>
        <Countdown />
      </Box>
    </Flex>
  );
};

export default TotalWrapper;
