import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Layout from './components/common/layout/Layout';
import Confirmation from './components/views/confirmation/Confirmation';
import Embargo from './components/views/embargo/Embargo';
import ErrorPage from './components/views/error/ErrorPage';
import PaymentError from './components/views/error/PaymentError';
import GiftCardConfirmation from './components/views/giftcards/GiftCardConfirmation';
import GiftCardPayment from './components/views/giftcards/GiftCardPayment';
import GiftCardSelect from './components/views/giftcards/GiftCardSelect';
import StartGiftCards from './components/views/giftcards/StartGiftCards';
import GiftMembershipConfirmation from './components/views/giftmembership/GiftMembershipConfirmation';
import GiftMembershipPayment from './components/views/giftmembership/GiftMembershipPayment';
import GiftMembershipSelect from './components/views/giftmembership/GiftMembershipSelect';
import StartGiftMembership from './components/views/giftmembership/StartGiftMembership';
import Home from './components/views/home/Home';
import ConcessionsConfirmation from './components/views/kiosk/ConcessionsConfirmation';
import ConcessionsLanding from './components/views/kiosk/ConcessionsLanding';
import Kiosk from './components/views/kiosk/Kiosk';
import StartConcessions from './components/views/kiosk/StartConcessions';
import Login from './components/views/login/Login';
import LoyaltyUpgradeConfirmation from './components/views/loyalty/LoyaltyUpgradeConfirmation';
import LoyaltyUpgradeLanding from './components/views/loyalty/LoyaltyUpgradeLanding';
import LoyaltyUpgradePayment from './components/views/loyalty/LoyaltyUpgradePayment';
import SelectUpgradeTier from './components/views/loyalty/SelectUpgradeTier';
import Payment from './components/views/payment/Payment';
import PhysicalGiftCardConfirmation from './components/views/physicalgiftcards/PhysicalGiftCardConfirmation';
import PhysicalGiftCardPayment from './components/views/physicalgiftcards/PhysicalGiftCardPayment';
import PhysicalGiftCardSelect from './components/views/physicalgiftcards/PhysicalGiftCardSelect';
import StartPhysicalGiftCards from './components/views/physicalgiftcards/StartPhysicalGiftCards';
import Refund from './components/views/refund/Refund';
import SeasonPassSeats from './components/views/seasonpass/SeasonPassSeats';
import SeasonPassShowtimes from './components/views/seasonpass/SeasonPassShowtimes';
import SeasonPassTickets from './components/views/seasonpass/SeasonPassTickets';
import SelectSeasonPass from './components/views/seasonpass/SelectSeasonPass';
import StartSeasonPass from './components/views/seasonpass/StartSeasonPass';
import Seats from './components/views/seats/Seats';
import SignupSelectTier from './components/views/signup/SelectSignupTier';
import SignupConfirmation from './components/views/signup/SignupConfirmation';
import SignupDetails from './components/views/signup/SignupDetails';
import SignupLanding from './components/views/signup/SignupLanding';
import SignupPayment from './components/views/signup/SignupPayment';
import StartTicketing from './components/views/startticketing/StartTicketing';
import Tickets from './components/views/tickets/Tickets';
import StartVouchers from './components/views/vouchers/StartVouchers';
import VouchersConfirmation from './components/views/vouchers/VouchersConfirmation';
import VouchersPayment from './components/views/vouchers/VouchersPayment';
import VouchersSelect from './components/views/vouchers/VouchersSelect';
import Warning from './components/views/warning/Warning';
import { STEP_PATHS } from './constants';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route
        path={`/${STEP_PATHS.STARTTICKETING}/:externalCinemaId/:externalSessionId`}
        element={<StartTicketing />}
      />
      <Route
        path={`/${STEP_PATHS.STARTCONCESSIONS}/:cinemaId`}
        element={<StartConcessions />}
      />
      <Route
        path={`${STEP_PATHS.STARTSEASONPASS}`}
        element={<StartSeasonPass />}
      />
      <Route path='/' element={<Layout />}>
        <Route path={`/paymenterror`} element={<PaymentError />} />
        <Route
          path={`/${STEP_PATHS.WARNING}/:externalCinemaId/:externalSessionId`}
          element={<Warning />}
        />
        <Route path={`/${STEP_PATHS.WARNING}`} element={<Warning />} />
        <Route
          path={`/${STEP_PATHS.LOGIN}/:externalCinemaId/:externalSessionId`}
          element={<Login />}
        />
        <Route
          path={`/${STEP_PATHS.TICKETS}/:externalCinemaId/:externalSessionId`}
          element={<Tickets />}
        />
        <Route
          path={`/${STEP_PATHS.SEATS}/:externalCinemaId/:externalSessionId`}
          element={<Seats />}
        />
        <Route
          path={`/${STEP_PATHS.KIOSK}/:externalCinemaId/:externalSessionId`}
          element={<Kiosk />}
        />
        <Route
          path={`/${STEP_PATHS.PAYMENT}/:externalCinemaId/:externalSessionId`}
          element={<Payment />}
        />
        <Route path={`/${STEP_PATHS.PAYMENT}`} element={<Payment />} />
        <Route
          path={`/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<Confirmation />}
        />
        <Route
          path={`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.LANDING}`}
          element={<ConcessionsLanding />}
        />
        <Route
          path={`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.LOGIN}`}
          element={<Login />}
        />
        <Route
          path={`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.SELECT}`}
          element={<Kiosk />}
        />
        <Route
          path={`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.PAYMENT}`}
          element={<Payment />}
        />
        <Route
          path={`/${STEP_PATHS.CONCESSIONS}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<ConcessionsConfirmation />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTCARDS}/`}
          element={<StartGiftCards />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.SELECT}`}
          element={<GiftCardSelect />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.PAYMENT}`}
          element={<GiftCardPayment />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTCARDS}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<GiftCardConfirmation />}
        />
        <Route path={`/${STEP_PATHS.VOUCHERS}/`} element={<StartVouchers />} />
        <Route
          path={`/${STEP_PATHS.VOUCHERS}/${STEP_PATHS.LOGIN}`}
          element={<Login />}
        />
        <Route
          path={`/${STEP_PATHS.VOUCHERS}/${STEP_PATHS.SELECT}`}
          element={<VouchersSelect />}
        />

        <Route
          path={`/${STEP_PATHS.VOUCHERS}/${STEP_PATHS.PAYMENT}`}
          element={<VouchersPayment />}
        />
        <Route
          path={`/${STEP_PATHS.VOUCHERS}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<VouchersConfirmation />}
        />
        <Route
          path={`/${STEP_PATHS.PHYSICALGIFTCARDS}/`}
          element={<StartPhysicalGiftCards />}
        />
        <Route
          path={`/${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.SELECT}`}
          element={<PhysicalGiftCardSelect />}
        />
        <Route
          path={`/${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.PAYMENT}`}
          element={<PhysicalGiftCardPayment />}
        />
        <Route
          path={`/${STEP_PATHS.PHYSICALGIFTCARDS}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<PhysicalGiftCardConfirmation />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTMEMBERSHIP}/`}
          element={<StartGiftMembership />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.SELECT}`}
          element={<GiftMembershipSelect />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.PAYMENT}`}
          element={<GiftMembershipPayment />}
        />
        <Route
          path={`/${STEP_PATHS.GIFTMEMBERSHIP}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<GiftMembershipConfirmation />}
        />
        <Route
          path={`/${STEP_PATHS.LOYALTY}/${STEP_PATHS.UPGRADE}`}
          element={<LoyaltyUpgradeLanding />}
        />
        <Route
          path={`/${STEP_PATHS.LOYALTY}/${STEP_PATHS.LOGIN}`}
          element={<Login />}
        />
        <Route
          path={`/${STEP_PATHS.LOYALTY}/${STEP_PATHS.SELECTTIER}`}
          element={<SelectUpgradeTier />}
        />
        <Route
          path={`/${STEP_PATHS.LOYALTY}/${STEP_PATHS.PAYMENT}`}
          element={<LoyaltyUpgradePayment />}
        />
        <Route
          path={`/${STEP_PATHS.LOYALTY}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<LoyaltyUpgradeConfirmation />}
        />
        <Route path={`/${STEP_PATHS.SIGNUP}`} element={<SignupLanding />} />
        <Route
          path={`/${STEP_PATHS.SIGNUP}/${STEP_PATHS.SELECTTIER}`}
          element={<SignupSelectTier />}
        />
        <Route
          path={`/${STEP_PATHS.SIGNUP}/${STEP_PATHS.DETAILS}`}
          element={<SignupDetails />}
        />
        <Route
          path={`/${STEP_PATHS.SIGNUP}/${STEP_PATHS.PAYMENT}`}
          element={<SignupPayment />}
        />
        <Route
          path={`/${STEP_PATHS.SIGNUP}/${STEP_PATHS.CONFIRMATION}/:orderId`}
          element={<SignupConfirmation />}
        />
        <Route path={`/embargo`} element={<Embargo />} />
        <Route path={`/error`} element={<ErrorPage />} />
        <Route path={`/refund/:orderId`} element={<Refund />} />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SELECT}/:externalCinemaId`}
          element={<SelectSeasonPass />}
        />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.LOGIN}/:externalCinemaId`}
          element={<Login />}
        />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.TICKETS}/:externalCinemaId`}
          element={<SeasonPassTickets />}
        />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SHOWTIMES}/:externalCinemaId`}
          element={<SeasonPassShowtimes />}
        />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.SEATS}/:externalCinemaId`}
          element={<SeasonPassSeats />}
        />
        <Route
          path={`${STEP_PATHS.SEASONPASS}/${STEP_PATHS.PAYMENT}/:externalCinemaId`}
          element={<Payment />}
        />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
