/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { EmblaCarouselType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Box, NavLink } from 'theme-ui';

import { ConcessionGrouping } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectFaBConcessions,
  selectKioskActiveCategory,
} from '../../../store/Selectors';

const FandBMiniCategories: React.FC = () => {
  const dispatch = useDispatch();

  const concessions = useSelector(selectFaBConcessions);
  const activeCategory = useSelector(selectKioskActiveCategory);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const tabContainer = useRef<null | HTMLDivElement>(null);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    dragFree: true,
    loop: false,
    slides: '.embla-slide',
  });

  const selectCategory = (i: number) => {
    if (tabContainer.current) {
      tabContainer.current.scrollIntoView({ block: 'start' });
    }
    dispatch(actionCreators.setKioskActiveCategory(i));
  };

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  const miniCategoriesDiv = document.querySelector('.mini-categories');
  const checkIfDivIsStuck = new IntersectionObserver(
    ([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
    { threshold: [1] }
  );

  miniCategoriesDiv && checkIfDivIsStuck.observe(miniCategoriesDiv);

  useEffect(() => {
    if (emblaApi) {
      onSelect(emblaApi);
      emblaApi.on('reInit', onSelect).on('select', onSelect);
    }
  }, [emblaApi, onSelect]);

  return (
    <>
      <div
        sx={{
          marginTop: '-44px',

          '@media screen and (min-width: 992px)': {
            marginTop: '-52px',
          },
        }}
      ></div>
      <Grid
        className='mini-categories sticky-top'
        gap={1}
        mt={2}
        sx={{
          paddingTop: '44px',
          transition: 'box-shadow 0.25s ease-in-out',

          '@media screen and (min-width: 992px)': {
            paddingTop: '52px',
          },

          '&.is-stuck': {
            boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: 'websiteBackground',

            '& .embla-button-prev': {
              background: 'categoryNavigationGradianLeftStart',
              left: 0,
            },

            '& .embla-button-next': {
              background: 'categoryNavigationGradianRightStart',
              right: 0,
            },
          }}
        >
          <div className='embla-viewport' ref={emblaRef}>
            <Box className='embla-container' py={3}>
              {concessions?.map((cg: ConcessionGrouping, i: number) => (
                <NavLink
                  key={`category-tab-button-${cg.title}`}
                  sx={{
                    border: 'mostReadableOnWebsiteBackgroundBorder',
                    backgroundColor: 'websiteBackground',
                    '&.active': {
                      backgroundColor: 'primary',
                      borderColor: 'primary',
                      color: 'mostReadableOnPrimaryBackground',
                    },
                    '&:hover': {
                      borderColor: 'primaryAlpha',
                      textDecoration: 'none',
                    },
                    '&:first-of-type': {
                      marginLeft: 4,
                    },
                    '&:last-of-type': {
                      marginRight: 4,
                    },
                  }}
                  onClick={() => selectCategory(i)}
                  onKeyDown={(e) =>
                    e.key === 'Enter' ? selectCategory(i) : null
                  }
                  className={`embla-slide ${
                    activeCategory === i ? 'active' : ''
                  }`}
                  py={2}
                  px={4}
                  mr={3}
                  tabIndex={0}
                >
                  {cg.title}
                </NavLink>
              ))}
            </Box>
            <button
              className='embla-button embla-button-next'
              disabled={nextBtnDisabled}
              tabIndex={-1}
            />
            <button
              className='embla-button embla-button-prev'
              disabled={prevBtnDisabled}
              tabIndex={-1}
            />
          </div>
        </Box>
      </Grid>
    </>
  );
};

export default FandBMiniCategories;
