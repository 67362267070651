import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { Concession, OrderDelivery } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useMaintainDeliveryItemCount } from '../../../hooks/useMaintainDeliveryItemCount';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { setGiftCardConcessions } from '../../../services/GiftCardHelpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoading,
  selectPhysicalGiftCardRecipient,
  selectQueryString,
  selectSelectedGiftCards,
  selectToken,
  selectMemberGiftCardGroup,
  selectConcessionsAdded,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import DeliveryDetails from '../../common/concessionsonlycommon/physicalgiftcard/DeliveryDetailsPhysical';
import PhysicalConcessionItem from '../../common/concessionsonlycommon/physicalgiftcard/SinglePhysicalGiftCardOptionRow';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const GiftMembershipSelect: React.FC = () => {
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();

  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const loading = useSelector(selectLoading);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const queryString = useSelector(selectQueryString);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const giftMembershipGroup = useSelector(selectMemberGiftCardGroup);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const hasConcessions = selectedConcessions
    ? selectedConcessions.list.some((x: Concession) => x.quantity > 0)
    : false;

  const selectedGiftCardsList = selectedConcessions.list.filter(
    (concession) => !concession.isDeliveryItem
  );

  const totalCardsSelected = selectedGiftCardsList.reduce(
    (a: number, b: Concession) => a + (b['quantity'] || 0),
    0
  );

  useMaintainDeliveryItemCount(totalCardsSelected);

  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/GiftCard/GetMemberGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        setGiftCardConcessions(
          response.content.listConcessionGrouping,
          queryString,
          dispatch
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!concessions && bookingData) {
      getConcessionData();
    }
  }, [bookingData, concessions, dispatch, queryString]);

  const addGiftCardsSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: false,
        isGiftWrapped: false,
        giftMessage: physicalGiftCardRecipient.message,
        deliveryAddress: {
          name: physicalGiftCardRecipient.name,
          address1: physicalGiftCardRecipient.addressLineOne,
          address2: physicalGiftCardRecipient.addressLineTwo,
          town: physicalGiftCardRecipient.city,
          postcode: physicalGiftCardRecipient.postcode,
        },
        billingAddress: {
          name: customer.name,
          email: customer.email,
        },
      };

      selectedConcessions?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedConcessions
          ? selectedConcessions.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/GiftCard/AddMemberGiftCards`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));

        boostNavigate.navigateToNextStep();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addGiftCardsSelectionToPos();
  };

  const getPhysicalGiftCardsRows = () => {
    return giftMembershipGroup?.items?.map((concession: Concession) => (
      <PhysicalConcessionItem
        {...concession}
        key={concession.id}
        defaultImage={content.giftMembership.giftMembershipDefaultImage}
        totalCardsSelected={totalCardsSelected}
      />
    ));
  };

  if (!content?.giftMembership || !config) return null;

  return (
    <Box
      className='giftcards'
      data-testid='giftcards'
      sx={{ textAlign: 'center' }}
    >
      <Heading size={1}>{content.giftMembership.stepOneHeading}</Heading>
      <ContainedRow>
        <Heading className='mt-3 text-center' size={2}>
          1. {content.giftMembership.selectGiftMembershipsHeading}
        </Heading>
        <RichText text={content.giftMembership.selectGiftMembershipsRichText} />
      </ContainedRow>

      {giftMembershipGroup && giftMembershipGroup.items.length > 0 ? (
        <Box mx={[0, 0, 8]}>
          <Box
            className='giftcard-rows-container'
            sx={{ my: 6, px: [0, 0, 3] }}
          >
            {getPhysicalGiftCardsRows()}
          </Box>

          {!showRecipientForm ? (
            <ActionButton
              disabled={!hasConcessions}
              mt={7}
              mx={5}
              onClick={() => setShowRecipientForm(true)}
              sticky={false}
              showIcon={true}
              contained
              variant='primary'
            >
              {content.giftMembership.continueToDetailsButtonText}
            </ActionButton>
          ) : (
            <>
              <DeliveryDetails isPageValidated={validateForm} />
              <ContainedRow>
                <RichText text={content.giftMembership.additionalRichText} />
              </ContainedRow>

              <ActionButton
                mt={7}
                mx={5}
                sticky={false}
                showIcon={true}
                contained
                onClick={handleContinueToPaymentClick}
                disabled={
                  !hasConcessions ||
                  !physicalGiftCardRecipient.isValid ||
                  !customer.isValid
                }
                stickyMobileDesktop={false}
                variant='primary'
              >
                {content.giftMembership.continueToPaymentButtonText}
              </ActionButton>
            </>
          )}
        </Box>
      ) : (
        !loading && (
          <ContainedRow styles={{ mt: 5 }}>
            <Box className='warning-container' p={5}>
              <p>{content.giftMembership.noGiftMembershipAvailableText}</p>
            </Box>
          </ContainedRow>
        )
      )}
    </Box>
  );
};

export default GiftMembershipSelect;
