import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Box, Theme, ThemeUIStyleObject } from 'theme-ui';

interface Props {
  classNameRow?: string;
  classNameCol?: string | undefined | boolean;
  dataTestId?: string;
  key?: string;
  styles?: ThemeUIStyleObject<Theme>;
}

const ContainedRow: React.FC<PropsWithChildren<Props>> = ({
  children,
  classNameRow,
  classNameCol,
  dataTestId,
  key,
  styles,
}) => {
  return (
    <Box
      className={classnames(classNameRow)}
      data-testid={dataTestId}
      key={key}
      sx={styles}
    >
      <Box className={classnames('contained', classNameCol)} px={3}>
        {children}
      </Box>
    </Box>
  );
};

export default ContainedRow;
