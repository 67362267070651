import React from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import VoucherImage from './VoucherImage';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  findModifiedConcessionMatch,
  findModifiedConcessionItemCost,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCurrencyConfig,
  selectCurrentCinema,
  selectSelectedGiftCards,
} from '../../../store/Selectors';
import QuantityButton from '../quantitybutton/QuantityButton';
import RichText from '../richtext/RichText';

interface Props extends Concession {
  defaultImage: string;
}

const VoucherItem: React.FC<Props> = ({
  id,
  description,
  extendedDescription,
  image,
  defaultImage,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
}) => {
  const dispatch = useDispatch();

  const currentCinema = useSelector(selectCurrentCinema);
  const selectedConcessions = useSelector(selectSelectedGiftCards);

  const concession: Concession = {
    id,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem: false,
    minVariableCost: 0,
    maxVariableCost: 0,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    code: '',
    isSoldOut: false,
    //deals: [],
  };

  //TODO add this to config
  const maxQuantityOfVouchers = 10;

  const currencyConfig = useSelector(selectCurrencyConfig);

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const selectedConcession: Concession | undefined =
    findModifiedConcessionMatch(concession, selectedConcessions);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const handleConcessionChange = (context: QuantitySelectorContext) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );
    concessionToChange.quantity = 1;
    if (context === 'add') {
      dispatch(actionCreators.addConcessionWithMods(concessionToChange, false));
    } else {
      dispatch(
        actionCreators.removeConcessionWithMods(concessionToChange, false)
      );
    }
  };

  const renderButton = (
    context: QuantitySelectorContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleConcessionChange(context)}
      />
    );
  };

  const renderQuantityButtons = () => {
    return (
      <div className='quantity-selector d-flex justify-content-center justify-content-md-right'>
        <div className='quantity-button-container'>
          {renderButton('remove', quantityInCart === 0)}
        </div>
        <div className='quantity-col d-flex align-items-center justify-content-center fw-bold'>
          {quantityInCart}
        </div>
        <div className='quantity-button-container'>
          {renderButton('add', maxQuantityOfVouchers <= quantityInCart)}
        </div>
      </div>
    );
  };

  const concessionQuantitySelector = () => {
    const priceToDisplay = currentCinema.hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;

    const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;

    return (
      <Row className='options-selector-container text-center flex-grow-1 mt-2 mt-md-0'>
        <Col>
          <Row className='align-items-center'>
            <Col
              xs={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 0 }}
              className='options-selector'
            >
              {renderQuantityButtons()}
            </Col>
            {itemPricing.costIncTax > 0 && (
              <Col xs={3} md={4} className='text-end'>
                {displayPrice(subTotal, currencyConfig)}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Row className='py-2 concession text-center align-items-center'>
      <Col xs={12} md={6}>
        <Row className='g-0 h-100'>
          <Col xs={'auto'} className='image-container pe-2'>
            <VoucherImage imageUrl={imagePath} />
          </Col>

          <Col className='details text-start d-flex flex-wrap align-items-center'>
            <div>
              <span className='h3 w-100'>{concession.description}</span>
              {concession.extendedDescription && (
                <RichText
                  className='tiny w-100'
                  styles={{ mt: 2 }}
                  text={concession.extendedDescription}
                />
              )}
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={6} className='px-0 px-md-2'>
        {concessionQuantitySelector()}
      </Col>
    </Row>
  );
};

export default VoucherItem;
