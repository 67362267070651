/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  Modifier,
  ModifierGroup,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { currentGroupQuantity } from '../../../services/KioskHelpers';
import {
  selectConfig,
  selectContent,
  selectCurrencyConfig,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  concession: Concession;
  refresh: number;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}

const FandBItemModalModifiers: React.FC<Props> = ({
  concession,
  refresh,
  setRefresh,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);

  const hideTax = config.currentCinema.hideTax;

  const handleSmartModifierQuantityChange = (
    context: QuantitySelectorContext,
    m: Modifier
  ) => {
    if (context === 'add') {
      m.quantity += 1;
    } else if (context === 'remove' && m.quantity > 0) {
      m.quantity -= 1;
    }
    setRefresh(refresh + 1);
  };

  const handleModifierQuantityChange = (
    context: QuantitySelectorContext,
    mg: ModifierGroup,
    m: Modifier
  ) => {
    if (context === 'add' && currentGroupQuantity(mg) < mg.maximumQuantity) {
      m.quantity += 1;
    } else if (context === 'remove' && m.quantity > 0) {
      m.quantity -= 1;
    }
    setRefresh(refresh + 1);
  };

  const hasOneMandatoryModifierInGroup = (
    mg: ModifierGroup,
    grpQuantity: number
  ) => {
    return (
      grpQuantity === 1 && mg.minimumQuantity === 1 && mg.modifiers.length === 1
    );
  };

  const radioOptionsSelector = (
    mg: ModifierGroup,
    m: Modifier,
    grpQuantity: number
  ) => {
    const selectModifier = () => {
      if (!hasOneMandatoryModifierInGroup(mg, grpQuantity)) {
        mg.modifiers.forEach((x: Modifier) => {
          x.quantity = x.id === m.id && m.quantity === 0 ? 1 : 0;
        });
      }
      setRefresh(refresh + 1);
    };

    return (
      <Box
        className={classnames('modifier-item', m.quantity >= 1 && 'selected')}
        key={`radioOptionsSelector_${mg.id}_${m.id}`}
        id='radioOptionsSelector'
      >
        <Box>
          <Button
            onClick={selectModifier}
            variant='link'
            disabled={m.isSoldOut}
            sx={{ width: '100%' }}
          >
            <Flex sx={{ paddingY: 2, alignItems: 'center', fontSize: 'small' }}>
              <div
                className={classnames(m.quantity >= 1 && 'b')}
                sx={{ textAlign: 'left', width: '100%' }}
              >
                {m.description}
              </div>
              {m.priceInCents > 0 && (
                <div className='modifier-cost'>
                  {displayPrice(
                    hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
                    currencyConfig
                  )}
                </div>
              )}

              {m.quantity >= 1 ? (
                <RadioSelectedSvg className='check' />
              ) : (
                <RadioUnselectedSvg className='check' />
              )}
            </Flex>
          </Button>
        </Box>
      </Box>
    );
  };

  const quantityOptionSelector = (
    mg: ModifierGroup,
    m: Modifier,
    grpQuantity: number
  ) => {
    return (
      <Flex
        sx={{
          paddingY: 2,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        key={`quantityOptionSelector_${mg.id}_${m.id}`}
        className='modifier-item'
        id='quantityOptionSelector'
      >
        <Box>
          <Box sx={{ fontSize: 'small' }}>{m.description}</Box>
          <Box sx={{ fontSize: 'tiny' }}>
            +
            {displayPrice(
              hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
              currencyConfig
            )}
          </Box>
        </Box>
        <Flex sx={{ alignItems: 'center', paddingX: 1 }}>
          <QuantityButton
            context='remove'
            disabled={m.quantity <= 0}
            onClick={() => handleModifierQuantityChange('remove', mg, m)}
            size='tiny'
          />
          <Box sx={{ textAlign: 'center', minWidth: '50px', paddingX: 1 }}>
            {m.quantity}
          </Box>
          <QuantityButton
            context='add'
            disabled={grpQuantity >= mg.maximumQuantity || m.isSoldOut}
            onClick={() => handleModifierQuantityChange('add', mg, m)}
            size='tiny'
          />
        </Flex>
      </Flex>
    );
  };

  const quantityOptionSelectorSmart = (mg: ModifierGroup, m: Modifier) => {
    return (
      <Box
        key={`quantityOptionSelectorSmart_${mg.id}_${m.id}`}
        className='modifier-item'
        id='quantityOptionSelectorSmart'
      >
        <Box>{m.description}</Box>
        <Box>
          <QuantityButton
            context='remove'
            disabled={m.quantity <= 0}
            onClick={() => handleSmartModifierQuantityChange('remove', m)}
            size='tiny'
          />
          <div className='flex-grow-1 quantity-col text-center'>
            {m.quantity}
          </div>
          <QuantityButton
            context='add'
            disabled={m.quantity >= mg.maximumQuantity || m.isSoldOut}
            onClick={() => handleSmartModifierQuantityChange('add', m)}
            size='tiny'
          />
        </Box>
        <Box className='tiny'>
          +
          {displayPrice(
            hideTax ? m.priceInCents : m.priceInCents - m.taxInCents,
            currencyConfig
          )}
        </Box>
      </Box>
    );
  };

  const modifierGroupRow = (mg: ModifierGroup) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const isOptional = mg.minimumQuantity === 0;
    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : `${mg.minimumQuantity} ${content.kiosk.groupRequiredDescription}`;
    if (grpQuantity > 0) {
      buttonText = `${grpQuantity} ${content.kiosk.groupSelectedDescription}`;
    }

    return (
      <div key={mg.id} id='modifierGroupRow'>
        <Box className='modifier-groups'>
          <Flex className='multi-option' sx={{ paddingBottom: 4 }}>
            <div className='b' sx={{ flexGrow: 1 }}>
              {isOptional && content.kiosk.optionalLabel
                ? `${mg.description} ${content.kiosk.optionalLabel}`
                : mg.description}
            </div>
            <div
              className='status'
              sx={{
                backgroundColor:
                  grpQuantity > 0
                    ? 'mostReadableOnWebsiteBackground'
                    : 'boxHeaderBackground',
                color:
                  grpQuantity > 0
                    ? 'leastReadableOnWebsiteBackground'
                    : 'mostReadableOnWebsiteBackground',
              }}
            >
              {buttonText}
            </div>
          </Flex>
          <div
            className={classnames(
              'multi-option-container bordered',
              grpQuantity > 0 && !mg.show && 'has-selection'
            )}
          >
            <div>
              {mg.modifiers.map((m: Modifier) =>
                mg.maximumQuantity > 1
                  ? quantityOptionSelector(mg, m, grpQuantity)
                  : radioOptionsSelector(mg, m, grpQuantity)
              )}
            </div>
          </div>
        </Box>
      </div>
    );
  };

  const multiModifierGroupRow = (mg: ModifierGroup, i: number) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const isOptional = mg.minimumQuantity === 0;
    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : `${mg.minimumQuantity} ${content.kiosk.groupRequiredDescription}`;
    if (grpQuantity > 0) {
      buttonText = `${grpQuantity} ${content.kiosk.groupSelectedDescription}`;
    }

    return (
      <Box
        key={`multiModifierGroupRow_${mg.id}_${i}`}
        id='multiModifierGroupRow'
      >
        <Flex className='multi-option' sx={{ paddingBottom: 4 }}>
          <div className='b' sx={{ flexGrow: 1 }}>
            {mg.description}
          </div>
          <div
            className='status'
            sx={{
              backgroundColor:
                grpQuantity > 0
                  ? 'mostReadableOnWebsiteBackground'
                  : 'boxHeaderBackground',
              color:
                grpQuantity > 0
                  ? 'leastReadableOnWebsiteBackground'
                  : 'mostReadableOnWebsiteBackground',
            }}
          >
            {buttonText}
          </div>
        </Flex>
        <div>
          {mg.modifiers.map((m: Modifier) =>
            mg.maximumQuantity > 1
              ? quantityOptionSelector(mg, m, grpQuantity)
              : radioOptionsSelector(mg, m, grpQuantity)
          )}
        </div>
      </Box>
    );
  };

  const multiSmartModifierGroupRow = (mg: ModifierGroup, i: number) => {
    const grpQuantity: number = currentGroupQuantity(mg);
    const isOptional = mg.minimumQuantity === 0;
    const totalUpdated = mg.modifiers.filter(
      (m: Modifier) => m.quantity > 1 || m.quantity == 0
    ).length;
    let buttonText = isOptional
      ? content.kiosk.optionalLabel
      : content.kiosk.groupRequiredDescription;
    if (totalUpdated > 0) {
      buttonText = `${totalUpdated} ${content.kiosk.groupChangesMadeDescription}`;
    }

    return (
      <Box
        className='quantity-options'
        key={`multiSmartModifierGroupRow_${mg.id}_${i}`}
        id='multiSmartModifierGroupRow'
      >
        <Box>
          <div
            className={classnames(
              'multi-option-container bordered',
              grpQuantity > 0 && !mg.show && 'has-selection'
            )}
          >
            <div className='multi-option'>
              <div>{content.kiosk.smartModifierGroupTitle}</div>
              <div
                className={classnames(
                  'tiny required-selection',
                  totalUpdated > 0 && 'valid'
                )}
              >
                {buttonText}
              </div>
            </div>
            <div>
              {mg.modifiers.map((m: Modifier) =>
                mg.maximumQuantity > 1
                  ? quantityOptionSelectorSmart(mg, m)
                  : radioOptionsSelector(mg, m, grpQuantity)
              )}
            </div>
          </div>
        </Box>
      </Box>
    );
  };

  return (
    <Box data-testid='concession-item'>
      {concession.modifierGroups?.length == 1 && (
        <Box className={concession.isSoldOut ? 'disabled' : undefined}>
          {concession.modifierGroups.map((mg: ModifierGroup) =>
            modifierGroupRow(mg)
          )}
        </Box>
      )}

      {concession.modifierGroups?.length > 1 && (
        <Box className='modifier-groups'>
          <Box>
            <Box className='multi-option-container bordered'>
              <Box>
                <Box className='modifier-groups'>
                  <Box
                    className={concession.isSoldOut ? 'disabled' : undefined}
                  >
                    {concession.modifierGroups?.map(
                      (mg: ModifierGroup, i: number) =>
                        mg.isSmart
                          ? multiSmartModifierGroupRow(mg, i)
                          : multiModifierGroupRow(mg, i)
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FandBItemModalModifiers;
