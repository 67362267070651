import {
  Concession,
  ConcessionPricing,
  ConcessionQuantityPricing,
  Modifier,
  ModifierGroup,
  SelectedConcessions,
} from '../@types/modelTypes';

export const currentGroupQuantity = (mg: ModifierGroup): number => {
  return mg.modifiers.reduce((a, b) => a + (b['quantity'] || 0), 0);
};
export const currentSmartGroupQuantity = (mg: ModifierGroup): number => {
  return mg.modifiers.reduce(
    (a, b) => a + (b['quantity'] == 0 || b['quantity'] > 1 ? 1 : 0),
    0
  );
};
export const findModifierQuantityCost = (
  m: Modifier,
  isSmart?: boolean
): ConcessionQuantityPricing => {
  const quantity = isSmart && m.quantity >= 1 ? m.quantity - 1 : m.quantity;
  const cost = quantity * m.priceInCents;
  const tax = quantity * m.taxInCents;
  return {
    quantityCostIncTax: cost,
    quantityTax: tax,
    quantityCostMinusTax: cost - tax,
  };
};

export const findModifiedConcessionItemCost = (
  c: Concession
): ConcessionPricing => {
  const concessionPricing: ConcessionPricing = {
    costIncTax: c.cost,
    tax: c.taxInCents,
    costMinusTax: 0,
  };
  if (c.modifierGroups) {
    // modifierGroups used in journey
    c.modifierGroups.forEach((mg: ModifierGroup) => {
      mg.modifiers.forEach((m: Modifier) => {
        const modifierPricing = findModifierQuantityCost(m, mg.isSmart);
        concessionPricing.costIncTax += modifierPricing.quantityCostIncTax;
        concessionPricing.tax += modifierPricing.quantityTax;
      });
    });
  }
  if (c.modifiers) {
    // modifiers used in confirmation page data
    c.modifiers.forEach((m: Modifier) => {
      const modifierPricing = findModifierQuantityCost(m);
      concessionPricing.costIncTax += modifierPricing.quantityCostIncTax;
      concessionPricing.tax += modifierPricing.quantityTax;
    });
  }
  concessionPricing.costMinusTax =
    concessionPricing.costIncTax - concessionPricing.tax;
  return concessionPricing;
};

export const findModifiedConcessionMatch = (
  concession: Concession,
  selectedConcessions: SelectedConcessions | null
): Concession | undefined => {
  if (!selectedConcessions) return undefined;
  const matched: Concession | undefined = selectedConcessions.list?.find(
    (x: Concession) =>
      x.id === concession.id &&
      x.cost === concession.cost &&
      x.modifierGroups.every((xGroup: ModifierGroup, groupIndex: number) => {
        const actionGroupModifiers: Modifier[] =
          concession.modifierGroups[groupIndex].modifiers;
        let modifiersMatch = true;
        if (xGroup.modifiers.length) {
          // check every modifier to ensure exact match
          for (let mi = 0; mi < xGroup.modifiers.length; mi++) {
            const modifierMatch: boolean =
              xGroup.modifiers[mi].id === actionGroupModifiers[mi].id &&
              xGroup.modifiers[mi].quantity ===
                actionGroupModifiers[mi].quantity;
            if (!modifierMatch) modifiersMatch = false;
          }
        }
        return modifiersMatch;
      })
  );
  return matched;
};

export const findTotalQuantityOfSelectedConcessionById = (
  concession: Concession,
  selectedConcessions: SelectedConcessions
): number => {
  const selectedConcessionById = selectedConcessions.list.filter(
    (x: Concession) => x.id === concession.id
  );

  const totalQuantityOfSelectedConcession = selectedConcessionById.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  return totalQuantityOfSelectedConcession;
};
