import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import CartSummaryDealsApplied from './CartSummaryDealsApplied';
import CartSummaryTicketOriginalPriceDisplay from './CartSummaryTicketOriginalPriceDisplay';
import { detailSxProp, priceSxProp, quantitySxProp } from './constants';

import { TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectTicketTypes,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryTickets: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const ticketTypes = useSelector(selectTicketTypes);
  const hasTickets =
    ticketTypes &&
    ticketTypes.ticketTypeModels.find((t: TicketTypeModel) => t.quantity > 0);

  if (!ticketTypes || !hasTickets) return null;

  return (
    <div className='cart-summary-row pb-0' data-testid='cs-tickets'>
      {ticketTypes.ticketTypeModels.map((t: TicketTypeModel, i: number) => {
        if (!t.quantity) return null;
        const priceToDisplay = hideTax ? t.price : t.price - t.tax;
        return (
          <Flex
            key={`${t.id}_${i}`}
            sx={{
              mb: 4,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              {t.voucherCode ? t.voucherDisplayName : t.displayName}
              <CartSummaryTicketOriginalPriceDisplay ticket={t} />
            </Box>
            <Flex sx={detailSxProp}>
              <Box className='tickets-quantity' sx={quantitySxProp}>
                {t.quantity}
              </Box>
              <Box className='price' sx={priceSxProp}>
                {displayPrice(
                  (t.quantity * (priceToDisplay * 100)) / 100,
                  currencyConfig
                )}
              </Box>
            </Flex>
          </Flex>
        );
      })}
      <CartSummaryDealsApplied />
    </div>
  );
};

export default CartSummaryTickets;
