import React from 'react';

import { Form } from 'react-bootstrap';

import { Cinema } from '../../../../@types/configTypes';
import landingContent from '../../../views/kiosk/LandingPageContent.json';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

interface Props {
  handleCinemaChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isKioskAvailable: boolean;
  allowCinemaSelection: boolean;
  cinemas: Cinema[] | null | undefined;
  cinemaId: string | undefined;
}

const CinemaSelector: React.FC<Props> = ({
  handleCinemaChange,
  isKioskAvailable,
  allowCinemaSelection,
  cinemas,
  cinemaId,
}) => {
  return (
    <>
      {allowCinemaSelection && cinemas && (
        <>
          <ContainedRow classNameRow='mt-3'>
            <div className='separator' />
          </ContainedRow>
          <ContainedRow classNameRow='mt-3' classNameCol='text-start'>
            <Heading size={1}>{landingContent.locationSelectorHeading}</Heading>
            <RichText
              styles={{ mt: 4 }}
              text={landingContent.locationSelectorRichText}
            />
            <Form.Group className='mb-2'>
              <Form.Label>{landingContent.locationSelectorLabel}</Form.Label>
              <Form.Select
                className='form-control'
                onChange={handleCinemaChange}
                value={cinemaId}
              >
                <option key={-1} value={-1}>
                  {landingContent.locationSelectorOptionText}
                </option>
                {cinemas.map((c: Cinema) => (
                  <option key={c.cinemaId} value={c.cinemaId}>
                    {c.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </ContainedRow>
        </>
      )}
      {allowCinemaSelection && cinemaId !== '-1' && !isKioskAvailable && (
        <ContainedRow>
          <div className='info-container mt-3 p-3'>
            <RichText text={landingContent.kioskPickupNotAvailableRichText} />
          </div>
        </ContainedRow>
      )}
    </>
  );
};

export default CinemaSelector;
