import React, { ReactElement, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  AddConcessionRequestModel,
  Concession,
  ConcessionGrouping,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConcessionsAdded,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
  selectToken,
  selectJourneyTypeConfig,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomerDetails from '../../common/customerdetails/CustomerDetails';
import Heading from '../../common/heading/Heading';
import ContainedRow from '../../common/layout/ContainedRow';
import TierItem from '../../common/loyalty/TierItem';
import RichText from '../../common/richtext/RichText';

const SelectUpgradeTier: React.FC = () => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();
  useValidateConcessionsJourney();
  const availableConcessions = useSelector(selectAllConcessions);
  const bookingData = useSelector(selectBookingData);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [showBilling, setShowBilling] = useState<boolean>(false);
  const shouldShowCinemaSelector = config?.loyalty?.allowCinemaSelection;

  const hasConcessions = availableConcessions?.some((x: ConcessionGrouping) =>
    x.items.some((y: Concession) => y.memberOnly)
  );

  const hasSelection = selectedConcessions?.list.some((x) => x.quantity >= 1);

  useEffect(() => {
    if (!hasSelection && showBilling) {
      setShowBilling(false);
    }
  }, [hasSelection, showBilling]);

  useEffect(() => {
    const getData = async () => {
      dispatch(actionCreators.setLoading(true));
      const response = await backend.post(
        `api/Member/GetUpgradeConcessions${
          shouldShowCinemaSelector ? '/' + config.currentCinema.cinemaId : ''
        }`,
        {
          dataToken: dataToken,
        }
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!availableConcessions && bookingData) {
      getData();
    }
  }, [
    availableConcessions,
    dispatch,
    bookingData,
    dataToken,
    shouldShowCinemaSelector,
    config?.currentCinema?.cinemaId,
  ]);

  const onContinueToBillingButtonClick = () => {
    setShowBilling(true);
  };

  const onActionButtonClick = async () => {
    await addConcessionsToPos();
  };

  const addConcessionsToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const data: AddConcessionRequestModel = {
        dataToken: dataToken,
        concessions: selectedConcessions ? selectedConcessions.list : [],
        journeyType: journeyTypeConfig.type,
        deliveryWindowInfo: null,
      };
      const response = await backend.post(
        'api/Member/UpgradeLoyaltyConcessions',
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        if (config.enableCountDown) {
          dispatch(
            actionCreators.setCountDown(response.content.secondsToExpiration)
          );
        }
        boostNavigate.navigateToNextStep();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            response.content.peachCode
          )
        );
      }
    } else {
      // if previously added concessions we need to call to remove them
      boostNavigate.navigateToNextStep();
    }
    dispatch(actionCreators.setLoading(false));
  };

  const renderUpgradeItems = () => {
    const loyaltyGroups: ConcessionGrouping[] | null | undefined =
      availableConcessions?.filter((x: ConcessionGrouping) => !!x.items.length);
    const list: ReactElement[] = [];
    loyaltyGroups?.forEach((loyaltyGroup: ConcessionGrouping) => {
      loyaltyGroup.items.forEach((concession: Concession) => {
        if (!concession.memberOnly) return;
        list.push(<TierItem {...concession} key={concession.id} />);
      });
    });
    return list;
  };

  if (!content?.loyalty) return null;

  return (
    <div className='loyalty' data-testid='loyalty'>
      <ContainedRow>
        <Heading size={1} className='text-center'>
          {content.loyalty.upgradeMainTitle}
        </Heading>
      </ContainedRow>

      <ContainedRow styles={{ mt: 4 }}>
        <Heading size={2} className='text-center'>
          1. {content.loyalty.upgradeSelectionHeading}
        </Heading>
        <RichText
          text={content.loyalty.upgradeInstructionsRichText}
          className='text-center'
        />
      </ContainedRow>

      {!hasConcessions && (
        <ContainedRow styles={{ mt: 5 }}>
          <Box className='warning-container' p={5}>
            <p>{content.loyalty.noUpgradesMessage}</p>
          </Box>
        </ContainedRow>
      )}
      <Flex sx={{ justifyContent: 'center', mx: -3 }}>
        <Box
          className='loyalty-rows-container'
          sx={{ mt: 6, maxWidth: '800px', mx: 'auto', width: '100%' }}
        >
          {renderUpgradeItems()}
        </Box>
      </Flex>

      {!showBilling ? (
        <ContainedRow styles={{ mt: 6 }}>
          <ActionButton
            onClick={onContinueToBillingButtonClick}
            disabled={!hasSelection}
            stickyMobileDesktop={false}
            showIcon
            contained
            mx={5}
            variant='primary'
          >
            {hasSelection
              ? content.loyalty.continueToBillingButtonText
              : content.loyalty.unselectedContinueButtonText}
          </ActionButton>
        </ContainedRow>
      ) : (
        <ContainedRow styles={{ mt: 6 }}>
          <Box mt={5}>
            <Heading size={2} className='text-center'>
              2. {content.loyalty.billingHeading}
            </Heading>
            <RichText
              text={content.loyalty.billingRichText}
              className='text-start'
            />
          </Box>
          <CustomerDetails isPageValidated={false} />
          <Box className='mt-4' mt={6}>
            <ActionButton
              onClick={onActionButtonClick}
              disabled={!customer.confirmEmailIsValid}
              stickyMobileDesktop={false}
              showIcon
              contained
              mx={5}
              variant='primary'
            >
              {hasSelection
                ? content.loyalty.continueToPaymentButtonText
                : content.loyalty.unselectedContinueButtonText}
            </ActionButton>
          </Box>
        </ContainedRow>
      )}
    </div>
  );
};

export default SelectUpgradeTier;
