import React from 'react';

import { useSelector } from 'react-redux';

import AppliedCardPaymentPromoBankCards from './AppliedCardPaymentPromoBankCards';
import AppliedMemberCards from './AppliedMemberCards';
import TicketSelector from './TicketSelector';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedCardPaymentPromoBankCard,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import {
  selectAppliedCardPaymentPromoBankCards,
  selectAppliedMemberCards,
  selectConfig,
} from '../../../store/Selectors';

interface GroupProps {
  orderHasMaxTickets: boolean;
  classNames: string;
  dataTestId: string;
  tickets: TicketTypeModel[];
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  ticketsHeading: string | undefined;
}

const TicketGroup: React.FC<GroupProps> = ({
  orderHasMaxTickets,
  tickets,
  classNames,
  dataTestId,
  handleTicketButtonClick,
  ticketsHeading,
}) => {
  const config = useSelector(selectConfig);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const appliedCardPaymentPromoBankCards: ValidatedCardPaymentPromoBankCard[] =
    useSelector(selectAppliedCardPaymentPromoBankCards);

  const isMultipleMemberTicketPurchaseAllowed =
    config.tickets.enableMultipleLoyaltyCardTicketPurchase;

  const areAllMemberTickets = tickets.every((ticket) => ticket.isMemberTicket);

  const showAppliedMemberCards =
    isMultipleMemberTicketPurchaseAllowed &&
    areAllMemberTickets &&
    appliedMemberCards?.length > 0;

  const areAllPromoTickets = tickets.every(
    (ticket) => ticket.isCardPaymentPromotionTicket
  );

  const showAppliedCardPaymentPromoBankCards =
    areAllPromoTickets && appliedCardPaymentPromoBankCards?.length > 0;

  return (
    tickets?.length > 0 && (
      <div className={classNames} data-testid={dataTestId}>
        {ticketsHeading && <h2 className='mt-4 mb-2'>{ticketsHeading}</h2>}
        <TicketSelector
          handleTicketButtonClick={handleTicketButtonClick}
          orderHasMaxTickets={orderHasMaxTickets}
          ticketTypeModels={tickets}
        />

        {showAppliedMemberCards && (
          <AppliedMemberCards
            tickets={tickets}
            handleTicketButtonClick={handleTicketButtonClick}
          />
        )}

        {showAppliedCardPaymentPromoBankCards && (
          <AppliedCardPaymentPromoBankCards
            handleTicketButtonClick={handleTicketButtonClick}
          />
        )}
      </div>
    )
  );
};

export default TicketGroup;
