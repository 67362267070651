import React from 'react';

import { useSelector } from 'react-redux';

import { selectContent } from '../../../../store/Selectors';
import Heading from '../../heading/Heading';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

const LoyaltyLandingHeadings = () => {
  const content = useSelector(selectContent);
  return (
    <ContainedRow>
      <Heading size={1} className='text-center'>
        {content.loyalty?.landingHeading}
      </Heading>
      <RichText styles={{ mt: 4 }} text={content.loyalty?.landingText} />
    </ContainedRow>
  );
};
export default LoyaltyLandingHeadings;
